<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="width-400 bg-white radius-20 ma-auto"
			>
				<div class="pa-20 under-line text-center">
					<h3 class="pop_tit pop_tit_logo"><slot name="title"></slot></h3>
				</div>

				<div class="pa-50-20">

					<div class="size-px-16 font-weight-500"><slot name="main-txt"></slot></div>

					<div class="mt-20 size-px-14 font-weight-400"><slot name="sub-txt"></slot></div>

					<slot name="content"></slot>
				</div>

				<div
					class="pa-20 text-center"
				>
					<button
						v-if="!not_cancel"
						@click="$emit('cancel')"
						class="btn-inline btn-dark-gray radius-20 mr-10"
					><slot name="name-cancel">취소</slot></button>
					<button
						@click="$emit('click')"
						class="btn-inline btn-primary radius-20"
					><slot name="name-confirm">확인</slot></button>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'PopupConfirm'
	,
	components: {PopupLayer},
	props: ['type', 'not_cancel']
	, data: function(){
		return {

		}
	}
}
</script>

<style>

	/* 팝업창 (모달) ver-3 */
	.full_layer_wrap {z-index: 1000; position: fixed; margin: 0 2rem; top: 0; left: 0; right: 0; bottom: 0;   }
	.full_layer_container { z-index: 1001; position: relative; min-width: 2.8rem; max-width: 42.5rem; height: 100%; margin: 0 auto; }
	.full_layer_wrap_inner { position: absolute; left: 0; right: 0; top: 50%; transform: translateY(-50%); width: 100%; }

	.view_cont_wrap { background-color: #fff; padding: 3rem 2rem; text-align: center; }
	.pop_tit{ padding: 0 0 2rem 0; font-size: 2rem; line-height: 2rem; font-weight: 700; color: var(--mafia-Blue); }
	.pop_tit_logo { padding: 7.6rem 0 0 0; background: url(../../assets/image/web/ico_logo_large.svg)no-repeat center top / 6rem 6rem ; }
	.target_card { display: block; padding: 0.5rem 0 0; font-size: 1.6rem; line-height: 1.6rem; font-weight: 700; color: var(--dark-Gray02); }
	.target_card > em { display: inline-block; padding: 0 0 0 1rem; font-size: 1.6rem; line-height: 1.6rem; font-weight: 700; color: var(--red); }

	.pop_desc { font-size: 1.6rem; font-weight: 700; color: var(--dark-Gray02) }
	.pop_desc span { padding: 0.5rem 0 0 0; display: block; font-size: 1.4rem; font-weight: 400; }

	.btn_wrap { display: flex; width: 100%; height: 5rem; line-height: 5rem;}
	.btn_wrap button { flex:1; font-size: 1.6rem; font-weight: 500; }

	.bg_overlay { z-index: 11; position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.5); }
</style>
