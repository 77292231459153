<template>
	<div class="wrap" style="background-color: #171C61 !important; height: 100%; display: flex; flex-direction: column; justify-content: center;">
		<div style="text-align: center">
			<template
				v-if="is_try"
			>
				<img
					:src="require('@/assets/image/intro_logo.svg')" style="vertical-align: middle !important;"
				/>
				<div class="text_guide pt-20">
					<h4>네트워크가 원활하지 않습니다.</h4>
				</div>
				<div class="text_guide_desc pt-15">
					<p>문제가 지속될 경우 관리자에게 문의하세요.</p>
				</div>
			</template>
		</div>
		<div
			style="padding: 10px; position: fixed; bottom: 0px; left: 0px; width: 100%"
		>
			<div
				v-if="is_try"
				class="intro_btn_wrap"
			>
				<div class="btn_area">
					<button
						@click="toTry"
						class="btn_l btn_fill_blue"
					>재시도</button>
				</div>
			</div>
		</div>

		<div
			v-if="is_loading"
			class="loading_wrap"
		>
			<div class="loading_bg"></div>
			<div class="dot_container">
				<div class="lds-ellipsis">
					<div><span class="hide">dot1</span></div>
					<div><span class="hide">dot2</span></div>
					<div><span class="hide">dot3</span></div>
					<div><span class="hide">dot4</span></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default{
		name: 'Intro'
		, props: ['user']
		, components: {  }
		,data: function(){
			return {
				program: {
					name: 'intro'
					, title: 'intro'
					, not_header: true
					, not_footer: true
				}
				, is_try: false
				, is_loading: true
			}	
		}
		, methods: {
			onLoad: function(){
				this.is_loading = true
				setTimeout( () => {
					this.is_loading = false
					this.is_try = true
				}, 10000)
			}
			, toTry: function(){
				this.$router.push({ name: 'index'})
			}
		}
		
		,mounted:  async function(){
			console.log(' intro created !!')
			this.$bus.$emit('onLoad', this.program)
			this.onLoad()
		}
	}
	
</script>



<style>

@keyframes spinner {
	to {transform: rotate(-360deg);}
}

.spin {
	animation: spinner 2s linear infinite;
}
</style>