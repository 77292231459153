import axios from 'axios'
import { encodeStorage } from "@/resources/storage/localStorage";

let domain = process.env.VUE_APP_DOMAIN
let domain_m = process.env.VUE_APP_DOMAIN_M
let dev = process.env.VUE_APP_DEV
let server = process.env.VUE_APP_SERVER
let local = process.env.VUE_APP_LOCAL
let location = window.location.href
let v_dev = process.env.VUE_APP_VERSION_DEV
let v_server = process.env.VUE_APP_VERSION_SERVER
let v_local = process.env.VUE_APP_VERSION_LOCAL

let baseUrl = ''

// 운영계 API 호출
if(location.indexOf(domain) > -1 || location.indexOf(domain_m) > -1){
	baseUrl = server + v_server

// 개발계 API 호출
}else if(location.indexOf((dev) > -1)){
	baseUrl = dev + v_dev

// 로컬 API 호출
}else{
	baseUrl = local + v_local
}

export async function Axios({ method, url, data, header, authorize, multipart, blob, isResult }){
// 	console.log('method', method)
	const instance = axios.create({
		baseURL: baseUrl
		,timeout: 30000
	})

	const getUrl = function(){

		let d = url.split('/::')
		if(d.length > 1){
			url = d[0]
		}

		return url
	}

	const getParams = function(){
		if(method == 'get'){
			return data
		}
	}

	const getData = function(){

		let formData = ''
		if(multipart){
			formData = new FormData();
			for(let key in data){
				if(Array.isArray(data[key])){

					for(let i = 0; i < data[key].length; i++) {

						if(Array.isArray(data[key][i])) {
							for (let sub_key in data[key][i]) {
								formData.append(`${key}[` + i + '].' + sub_key, transVal(data[key][i][sub_key]))
							}
						}else{
							formData.append(`${key}`, transVal(data[key][i]))
						}
					}
				}else {
					formData.append(key, transVal(data[key]))
				}
			}

			return formData
		}else{
			return { d: data }
		}
	}

	const transVal = function(value){
		if( value === "" || value === null || value === undefined){
			return ''
		}else{
			return value
		}
	}

	const getHeader = function(){

		let default_header = {
			'Content-Type': 'application/json'
			, 'v': '1.0.0'
			, 'Content-Security-Policy' : 'upgrade-insecure-requests'
		}

		let d = url.split('/::')
		if(d.length > 1){
			default_header.v = d[1]
			url = d[0]
		}

		let session_token = encodeStorage.getSessionToken()
		//console.log('session_token', session_token)

		if(session_token){
			default_header.a = session_token
			//console.log('default_header', default_header)
		}


		if(!authorize){
			//default_header.Authorization = 'Bearer ' + (TOKEN ? TOKEN : sessionStorage.getItem(process.env.VUE_APP_NAME + 'AT'))
			// console.log('TOKEN :', TOKEN)
		}

		if(multipart){
			default_header['Content-Type'] = 'multipart/form-data'
		}

		if(blob){
			default_header['responseType'] = 'blob'
		}

		return header ? header : default_header
	}

	instance.interceptors.response.use(

	)

	console.log('to instance')
	return await instance({
		method: method
		,headers: getHeader()
		,url: getUrl()
		,params: getParams()
		,data: getData()
	}).then((result) => {
		console.log('instance then')
		if(isResult){
			return {success: true, data: result.data}
		}else {
			const data = result.data
			let message = data.resultinfo.result_msg ? data.resultinfo.result_msg : data.resultinfo.result_div_cd == 'OK' ? '' : '요청 처리 실패'

			if(data.resultinfo.result_div_cd != 'OK'){
				// console.log('axios result', getUrl(), JSON.stringify(getParams()), JSON.stringify(getData()), message)
			}

			return {
				success: data.resultinfo.result_div_cd == 'OK' ? true : false
				, data: data.d
				, message: message
				, code: data.resultinfo.result_cd
			}
		}
	}).catch( (error) => {
		// console.log('instance catch', error.response)
		if(error.response.status){

			// sessionStorage.setItem('Error', error.response.status + '')
			error.message = ''

			switch (error.response.status){
				case 401:
					document.location.reload()
					break;
				case 400:
					error.message = '잘못된 요청입니다. 관리자에게 문의하세요.'
					break;
				case 500:
					error.message = '요청 처리중 문제가 발생하였습니다.'
					break;
			}

			// document.location.reload()
			//document.location.href = '/'
		}
		return {
			success: false
			, message: error.message
			, code: error.response
		}
	})
}



