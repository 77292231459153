import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	
	{
		path: '/index'
		,name: 'index'
		// ,component: () => import('@/view/Nft/mafia018.vue')
		,component: () => import('@/view/Cartel/mafia044')
	}
	, {
		path: '/'
		,name: 'main'
		// ,component: () => import('@/view/Nft/mafia018.vue')
		,component: () => import('@/view/Cartel/mafia044')
	}
	, {
		path: '/cartel'
		,name: 'cartel'
		// ,component: () => import('@/view/Nft/mafia018.vue')
		,component: () => import('@/view/Cartel/mafia044')
	}
	, {
		path: '/auth/apple_return'
		,name: 'appleReturn'
		,component: () => import('@/view/Auth/apple_return.vue')
	}
	, {
		path: '/auth/personal'
		,name: 'personal'
		,component: () => import('@/view/Auth/personal.vue')
	}
	, {
		path: '/auth/agreement'
		,name: 'agreement'
		,component: () => import('@/view/Auth/agreement.vue')
	}
	, {
		path: '/auth/update'
		,name: 'update'
		,component: () => import('@/view/Main/mafia001-1.vue')
	}
	,{
		path: '/auth/intro'
		,name: 'intro'
		,component: () => import('@/view/Main/mafia001.vue')
	}
	,{
		path: '/auth/intro/mafia001'
		,name: 'mafia001'
		,component: () => import('@/view/Main/mafia001.vue')
	}
	,{
		path: '/auth/intro/mafia002'
		,name: 'mafia002'
		,component: () => import('@/view/Main/mafia002.vue')
	}
	,{
		path: '/auth/intro/mafia003'
		,name: 'mafia003'
		,component: () => import('@/view/Main/mafia003.vue')
	}
	,{
		path: '/nft'
		,name: 'nft'
		,component: () => import('@/view/Nft/mafia018.vue')
	}
	,{
		path: '/nft/mafia018'
		,name: 'mafia018'
		,component: () => import('@/view/Nft/mafia018.vue')
	}
	,{
		path: '/nft/mafia019/:card_type/:id'
		,name: 'mafia019'
		,component: () => import('@/view/Nft/mafia019.vue')
	}
	,{
		path: '/nft/mafia0191/:card_type/:id'
		,name: 'mafia0191'
		,component: () => import('@/view/Nft/mafia019-1.vue')
	}
	,{
		path: '/nft/mafia0192/:id'
		,name: 'mafia0192'
		,component: () => import('@/view/Nft/mafia019-2.vue')
	}
	,{
		path: '/nft/mafia020/:card_type/:id'
		,name: 'mafia020'
		,component: () => import('@/view/Nft/mafia020.vue')
	}
	,{
		path: '/nft/mafia0201'
		,name: 'mafia0201'
		,component: () => import('@/view/Nft/mafia020-1.vue')
	}
	,{
		path: '/drops/:card_type?'
		,name: 'drops'
		,component: () => import('@/view/Drops/mafia039-1.vue')
	}
	,{
		path: '/drops/mafia0392/:nft_card_div_code/:nft_sl_number/:blockchain_div_code/:sl_coin_token_code'
		,name: 'mafia0392'
		,component: () => import('@/view/Drops/mafia039-2.vue')
	}
	,{
		path: '/drops/mafia03921/:nft_card_div_code/:nft_sl_number/:blockchain_div_code/:sl_coin_token_code'
		,name: 'mafia03921'
		,component: () => import('@/view/Drops/mafia039-2-1.vue')
	}
	,{
		path: '/drops/mafia040'
		,name: 'mafia040'
		,component: () => import('@/view/Drops/mafia040.vue')
	}
	,{
		path: '/drops/mafia043/:nft_id'
		,name: 'mafia043'
		,component: () => import('@/view/Drops/mafia043.vue')
	}
	,{
		path: '/auth/mafia004'
		,name: 'mafia004'
		,component: () => import('@/view/Auth/mafia004.vue')
	}
	,{
		path: '/auth/agree'
		,name: 'agree'
		,component: () => import('@/view/Auth/mafia004.vue')
	}
	,{
		path: '/auth/mafia005'
		,name: 'mafia005'
		,component: () => import('@/view/Auth/mafia005.vue')
	}
	,{
		path: '/auth/join'
		,name: 'join'
		,component: () => import('@/view/Auth/mafia005.vue')
	}
	,{
		path: '/auth/sms'
		,name: 'sms'
		,component: () => import('@/view/Auth/sms.vue')
	}
	,{
		path: '/auth/pin_sms'
		,name: 'pin_sms'
		,component: () => import('@/view/Auth/pin_sms.vue')
	}
	,{
		path: '/auth/pin_sms/:random'
		,name: 'pin_sms1'
		,component: () => import('@/view/Auth/pin_sms1.vue')
	}
	,{
		path: '/auth/nice'
		,name: 'nice'
		,component: () => import('@/view/Auth/nice.vue')
	}
	,{
		path: '/auth/mafia010'
		,name: 'mafia010'
		,component: () => import('@/view/Auth/mafia010.vue')
	}
	,{
		path: '/auth/mafia0101'
		,name: 'mafia0101'
		,component: () => import('@/view/Auth/mafia010-1.vue')
	}
	,{
		path: '/auth/mafia0101'
		,name: 'join_step2'
		,component: () => import('@/view/Auth/mafia010-1.vue')
	}
	,{
		path: '/auth/login/:auto?'
		,name: 'login'
		,component: () => import('@/view/Auth/mafia011.vue')
	}
	,{
		path: '/auth/login/kakao'
		,name: 'kakaoLogin'
		,component: () => import('@/view/Auth/kakaoLogin.vue')
	}
	,{
		path: '/auth/join/kakao'
		,name: 'kakaoJoin'
		,component: () => import('@/view/Auth/kakaoJoin.vue')
	}
	,{
		path: '/auth/join/otp'
		,name: 'googleOtp'
		,component: () => import('@/view/Auth/mafia111-7.vue')
	}
	,{
		path: '/auth/mafia009/:type?'
		,name: 'mafia009'
		,component: () => import('@/view/Auth/mafia009.vue')
	}
	,{
		path: '/auth/mafia011'
		,name: 'mafia011'
		,component: () => import('@/view/Auth/mafia011.vue')
	}
	,{
		path: '/auth/pin/:pin_type?'
		,name: 'pin'
		,component: () => import('@/view/Auth/mafia007.vue')
	}
	,{
		path: '/auth/mafia007/:pin_type?'
		,name: 'mafia007'
		,component: () => import('@/view/Auth/mafia007.vue')
	}
	,{
		path: '/wallet'
		,name: 'wallet'
		,component: () => import('@/view/Wallet/mafia030.vue')
	}
	,{
		path: '/wallet/mafia030'
		,name: 'mafia030'
		,component: () => import('@/view/Wallet/mafia030.vue')
	}
	,{
		path: '/wallet/mafia031'
		,name: 'mafia031'
		,component: () => import('@/view/Wallet/mafia031.vue')
	}
	,{
		path: '/wallet/mafia032/:id'
		,name: 'mafia032'
		,component: () => import('@/view/Wallet/mafia032.vue')
	}
	,{
		path: '/staking'
		,name: 'staking'
		,component: () => import('@/view/Staking/mafia036.vue')
	}
	,{
		path: '/staking/mafia0362/:card_type/:id'
		,name: 'mafia0362'
		,component: () => import('@/view/Staking/mafia036-2.vue')
	}
	,{
		path: '/staking/mafia007'
		,name: 'mafia007'
		,component: () => import('@/view/Auth/mafia007.vue')
	}
	,{
		path: '/myPage'
		,name: 'myPage'
		,component: () => import('@/view/Mypage/mafia100.vue')
	}
	,{
		path: '/myPage'
		,name: 'mafia100'
		,component: () => import('@/view/Mypage/mafia100.vue')
	}
	,{
		path: '/myPage/credit'
		,name: 'mafiaCredit'
		,component: () => import('@/view/Mypage/mafia-credit')
	}
	,{
		path: '/myPage/credit/regist'
		,name: 'mafiaCreditRegist'
		,component: () => import('@/view/Mypage/mafia-credit-regist')
	}
	,{
		path: '/myPage/mafia118'
		,name: 'mafia118'
		,component: () => import('@/view/Mypage/mafia118-1.vue')
	}
	,{
		path: '/myPage/mafia101'
		,name: 'mafia101'
		,component: () => import('@/view/Mypage/mafia101.vue')
	}
	,{
		path: '/myPage/mafia102'
		,name: 'mafia102'
		,component: () => import('@/view/Mypage/mafia102.vue')
	}
	,{
		path: '/myPage/mafia102-1'
		,name: 'mafia102-1'
		,component: () => import('@/view/Mypage/mafia102-1.vue')
	}
	,{
		path: '/myPage/mafia104'
		,name: 'mafia104'
		,component: () => import('@/view/Mypage/mafia104.vue')
	}
	,{
		path: '/myPage/mafia105'
		,name: 'mafia105'
		,component: () => import('@/view/Mypage/mafia105.vue')
	}
	,{
		path: '/myPage/mafia107/:type?'
		,name: 'mafia107'
		,component: () => import('@/view/Mypage/mafia107.vue')
	}
	,{
		path: '/myPage/mafia017'
		,name: 'mafia017'
		,component: () => import('@/view/Mypage/mafia017.vue')
	}
	,{
		path: '/myPage/mafia111'
		,name: 'mafia111'
		,component: () => import('@/view/Mypage/mafia111.vue')
	}
	,{
		path: '/myPage/mafia1113'
		,name: 'mafia1113'
		,component: () => import('@/view/Mypage/mafia111-3.vue')
	}
	,{
		path: '/myPage/mafia1114'
		,name: 'mafia1114'
		,component: () => import('@/view/Mypage/mafia111-4.vue')
	}
	,{
		path: '/myPage/mafia112'
		,name: 'mafia112'
		,component: () => import('@/view/Mypage/mafia112.vue')
	}
	,{
		path: '/myPage/mafia117'
		,name: 'mafia117'
		,component: () => import('@/view/Mypage/mafia117.vue')
	}
	,{
		path: '/myPage/mafia1111'
		,name: 'mafia1111'
		,component: () => import('@/view/Mypage/mafia1111.vue')
	}
/*	,{
		path: '/community/notice'
		, name: 'notice'
		, component: () => import('@/view/Community/BbsList')
	}*/
	,{
		path: '/community/notice'
		, name: 'notice'
		, component: () => import('@/view/Community/BbsList-1')
	}
	,{
		path: '/community/notice/info'
		, name: 'bbs_detail'
		, component: () => import('@/view/Community/BbsDetail')
	}
/*	,{
		path: '/community/faq'
		, name: 'faq'
		, component: () => import('@/view/Community/FaqList')
	}*/
	,{
		path: '/community/faq'
		, name: 'faq'
		, component: () => import('@/view/Community/FaqList-1')
	}
	,{
		path: '/cartel/mafia044'
		, name: 'mafia044'
		, component: () => import('@/view/Cartel/mafia044')
	}
	,{
		path: '/cartel/mafia044'
		, name: 'cartel'
		, component: () => import('@/view/Cartel/mafia044')
	}
	,{
		path: '/cartel/mafia046'
		, name: 'mafia046'
		, component: () => import('@/view/Cartel/mafia046')
	}
	,{
		path: '/mafia047/:search_value?'
		, name: 'mafia047'
		, component: () => import('@/view/Cartel/mafia047')
	}
	,{
		path: '/cartel/mafia048/:idx/:search_value?'
		, name: 'mafia048'
		, component: () => import('@/view/Cartel/mafia048')
	}
	,{
		path: '/cartel/mafia049/:idx'
		, name: 'mafia049'
		, component: () => import('@/view/Cartel/mafia049')
	}
	,{
		path: '/cartel/mafia0491/:idx'
		, name: 'mafia049'
		, component: () => import('@/view/Cartel/mafia049-1')
	}
	,{
		path: '/cartel/mafia053/:idx/:code/:b_id'
		, name: 'mafia053'
		, component: () => import('@/view/Cartel/mafia053')
	}
	,{
		path: '/cartel/mafia0531/:idx/:code/:b_id'
		, name: 'mafia0531'
		, component: () => import('@/view/Cartel/mafia053-1')
	}
	,{
		path: '/cartel/mafia050/:idx'
		, name: 'mafia050'
		, component: () => import('@/view/Cartel/mafia050')
	}
	,{
		path: '/cartel/mafia057/:idx'
		, name: 'mafia057'
		, component: () => import('@/view/Cartel/mafia057')
	}
	,{
		path: '/cartel/mafia058/:idx/:code/:b_id/:bbs_id'
		, name: 'mafia058'
		, component: () => import('@/view/Cartel/mafia058')
	}
	,{
		path: '/cartel/comment/:idx/:code/:b_id/:bbs_id'
		, name: 'mafiaCommentList'
		, component: () => import('@/view/CartelComment/mafiaCommentList')
	}
	,{
		path: '/cartel/comment/:idx/:code/:b_id/:bbs_id/:comment_id'
		, name: 'mafia0632'
		, component: () => import('@/view/Cartel/mafia063-2')
	}
	,{
		path: '/cartel/mafia074/:idx'
		, name: 'mafia074'
		, component: () => import('@/view/Cartel/mafia074')
	}
	,{
		path: '/cartel/mafia0741/:idx'
		, name: 'mafia0741'
		, component: () => import('@/view/Cartel/mafia074-1')
	}
	,{
		path: '/cartel/mafia076/:idx'
		, name: 'mafia076'
		, component: () => import('@/view/Cartel/mafia076')
	}
	,{
		path: '/cartel/mafia082/:idx'
		, name: 'mafia082'
		, component: () => import('@/view/Cartel/mafia082')
	}
	,{
		path: '/cartel/mafia083/:idx'
		, name: 'mafia083'
		, component: () => import('@/view/Cartel/mafia083')
	}
	,{
		path: '/cartel/mafia084/:idx'
		, name: 'mafia084'
		, component: () => import('@/view/Cartel/mafia084')
	}
	,{
		path: '/cartel/mafia0841/:idx'
		, name: 'mafia0841'
		, component: () => import('@/view/Cartel/mafia084-1')
	}
	,{
		path: '/cartel/mafia0831/:idx'
		, name: 'mafia0831'
		, component: () => import('@/view/Cartel/mafia083-1')
	}
	,{
		path: '/cartel/mafia091/:idx'
		, name: 'mafia091'
		, component: () => import('@/view/Cartel/mafia091')
	}
	,{
		path: '/cartel/mafia0911/:idx'
		, name: 'mafia0911'
		, component: () => import('@/view/Cartel/mafia091-1')
	}
	,{
		path: '/cartel/mafia119'
		, name: 'mafia119'
		, component: () => import('@/view/Cartel/mafia119')
	}
	,{
		path: '/cartel/mafia122/:idx/:type?'
		, name: 'mafia122'
		, component: () => import('@/view/Cartel/mafia122')
	}
	,{
		path: '/cartel/mafia125/:idx/:b_id?'
		, name: 'mafia125'
		, component: () => import('@/view/Cartel/mafia125')
	}
	,{
		path: '/cartel/mafia127/:idx/:code?/:b_id?'
		, name: 'mafia127'
		, component: () => import('@/view/CartelBoard/mafia127')
	}
	,{
		path: '/cartel/mafia1271/:idx/:code?/:b_id?'
		, name: 'mafia1271'
		, component: () => import('@/view/CartelBoard/mafia127-1')
	}
	,{
		path: '/cartel/mafia1272/:idx/:code/:b_id/:bbs_id'
		, name: 'mafia1272'
		, component: () => import('@/view/CartelBoard/mafia127-2')
	}
	,{
		path: '/cartel/mafia1211/:idx'
		, name: 'mafia1211'
		, component: () => import('@/view/Cartel/mafia121-1')
	}
	, {
		path: '/test/otp_confirm'
		, name: 'test_otp_confirm'
		, component: () => import('@/view/Test/otp_confirm')
	}
	,{
		path: '/sample/reset'
		, name: 'reset'
		, component: () => import('@/view/Sample/Reset')
	}
	,{
		path: '*',
		name: '404',
		component: () => import('@/view/Error/NotFound.vue')
	}
	,{
		path: '/Error/401',
		name: 'Unauthorized',
		component: () => import('@/view/Error/Unauthorized.vue')
	}
	,{
		path: '/duplicate',
		name: 'DuplicatePage',
		component: () => import('@/view/Layout/DuplicatePage')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	next();
});

export default router
