<template>

	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="flex-column radius-20 text-left  radius-20 overflow-hidden"
				style="min-width: 320px; max-width: 480px; margin: 0 auto;"
			>
				<div class="bg-primary flex-row justify-space-between items-center pa-10-20">
					<h4 class="color-white">멤버 초대</h4>

					<v-icon
						@click="$emit('cancel')"
						class="color-white"
					>mdi-close-circle</v-icon>
				</div>

				<div class="bg-white">
					<div class="pa-10-20 flex-row under-line">
						<div class="flex-1">
							<input
								v-model="item_search.search_value"
								type="text" placeholder="회원 검색"
								maxlength="20"
							>
						</div>

						<v-icon
							v-if="item_search.search_value"
							@click="item_search.search_value = ''; getData()"
							class="color-gray"
						>mdi-close-circle</v-icon>

						<v-icon
							@click="getData"
							class="ml-10 color-gray"
						>mdi-magnify</v-icon>
					</div>

					<div class="pa-10-20">
						<div>
							<div class="flex-row justify-space-between items-center">
								<h3>친구</h3>
								<span>{{ friend_list_count }}명</span>
							</div>

							<ul class="mt-10">
								<li
									v-for="(item, index) in invite_friend"
									:key="'item_' + index"
								>
									<div class="picture">
										<img
											v-if="item.profle_nft_card_img_url"
											:src="$request.upload_url(item.profle_nft_card_img_url)"
										>
										<img
											v-else
											:src="require('@/assets/image/@profile_inner.png')" alt="추천카르텔"
										/>
									</div>
									<div class="text_area">
										<strong>{{ item.nickname }}</strong>
										<p class="textzone textzone">
											{{  item.state_message }}
										</p>
									</div>
									<div class="ct_invite_btn">
										<button
											@click="postInvite(item)"
											class="btn_outline_blue btn_s"
											:disabled="item.invitation_possible_fg != 'Y'"
										>초대</button>
									</div>
								</li>
							</ul>
							<div
								v-if="invite_friend.length == 0"
								class="pa-10 flex-column justify-center items-center"
							>
								<div><img :src="require('@/assets/image/list_none.png')" alt="이력없음" style="width: 120px"></div>
								<div class="mt-20">초대가능한 친구가 없습니다.</div>
							</div>
						</div>

						<div class="mt-20">
							<div class="flex-row justify-space-between items-center">
								<h3>회원</h3>
								<span>{{ member_list_count }}명</span>
							</div>

							<div class="mt-20">
								<ul class="pr-10 pb-10 overflow-hidden">
									<li
										v-for="(item, index) in invite_member"
										:key="'item_' + index"
										class="flex-row items-center"
									>
										<div class="mr-10 radius-100 overflow-hidden">
											<img
												v-if="item.profle_nft_card_img_url"
												:src="$request.upload_url(item.profle_nft_card_img_url)"
											>
											<img
												v-else
												:src="require('@/assets/image/@profile_inner.png')" alt="추천카르텔"
											/>
										</div>
										<div class="flex-1">
											<div>{{ item.nickname }}</div>
											<div>{{ item.state_message }}</div>
										</div>
										<div class="">
											<button
												@click="postInvite(item)"
												class="btn btn-primary radius-20 pa-10-20 size-px-12"
												:disabled="item.invitation_possible_fg != 'Y'"
											>초대</button>
										</div>
									</li>
								</ul>

								<div
									v-if="invite_member.length == 0"
									class="pa-10 flex-column justify-center items-center"
								>
									<div><img :src="require('@/assets/image/list_none.png')" alt="이력없음" style="width: 120px"></div>
									<div class="mt-20">초대가능한 멤버가 없습니다.</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'mafia057'
		,
		components: {PopupLayer},
		props: ['user']
		, data: function(){
			return {
				program: {
					name: '회원 초대'
					, title: '회원 초대'
					, type: 'cartel_sub'
					, not_header: true
					, not_footer: true
				}
				, items_member: []
				, items_friend: []
				, item_cartel: {}
				, item_search: {}
				, all_list_count: 0
				, friend_list_count: 0
				, member_list_count: 0
			}
		}
		, computed: {
			invite_member: function(){
				let t = []
				this.items_member.filter(function(item_friend, index){

					item_friend.index = index
					if(item_friend.friend_fg == 'N'){
						t.push(item_friend)
					}
				})

				return t
			}
			, invite_friend: function(){

				let t = []
				this.items_member.filter(function(item_friend, index){

					item_friend.index = index
					if(item_friend.friend_fg == 'Y'){
						t.push(item_friend)
					}
				})

				return t
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_invite
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, srchtext: this.item_search.search_value
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_member = result.data.member_list
						this.all_list_count = result.data.all_list_count
						this.friend_list_count = result.data.friend_list_count
						this.member_list_count = result.data.all_list_count
						this.$storage.setQuery(this.item_search)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
			, getMyFriend: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_friends_list
						, data: {
							member_number: this.user.member_number
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_friend = result.data.friend_list
						this.$storage.setQuery(this.item_search)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
			,getCartel: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
						this.items_cartel_notice = result.data.notice_mttrs_list
						this.items_cartel_bbs = result.data.post_list

						switch (this.item_cartel.cartl_concern_sphere_code){
							case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
							case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
							case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
							case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postInvite: async function(item){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_invite
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, invitation_member_number: item.member_number
						}
						, type: true
					})

					if(result.success){
						this.$bus.$emit('notify', { type: 'success', message: '초대요청이 완료되었습니다'})

						this.$set(this.items_member[item.index], 'invitation_possible_fg', 'N')
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		,async created() {
			await this.getCartel()
			await this.getData()
		}

	}
</script>