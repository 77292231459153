

export const codes = {
	metaInfo: {
		title: 'MAFIA'
		,copyright: 'MAFIA'
	}
	, permission_underboss: [
		'undrbos_mangement'
		, 'member_mangement'
		, 'notice_mttrs_reg'
		, 'board_creation'
		, 'manage_grade'
	]
	, permission_manager: [
		'cartl_admin_mangement'
		, 'cartl_member_mangement'
		, 'cartl_notice_mttrs_reg'
		, 'cartl_board_creation'
		, 'cartl_member_grade_mangement'
	]
}