<template>
	<div class="under-line">
		<div
			class="color-white pa-30 bg-gray cartel-info "
			:style="'background-image: url(' + cartel.cartl_img_url + '); background-repeat: repeat; background-position: center center;'"
		>
			<div
				class="full-height flex-row "
				style="width: 1200px; margin: 0 auto"
			>
				<div
					class="flex-column justify-end cartel_btn_area"
				>
					<div
						v-if="item_member_info.cartl_member_grade_number"
						class="mt-30"
					>

						<div class="btn-primary btn-inline mr-10 radius-20">{{ item_member_info.cartl_member_grade_name }}</div>
						<div>
							<button
								v-if="is_join_confirm"
								@click="onInvite"
								class="m_invite cursor-pointer"
							>
								<span>멤버초대</span>
							</button>
							<div
								v-else-if="is_join_wait"
								class="btn btn-blue radius-20 pa-10-20"
							>
								<span>{{  cartel.cartl_entry_state_name }}</span>
							</div>

							<div
								v-else-if="is_join_cancel"
								class="btn btn-blue radius-20 pa-10-20"
							>
								<span>{{  cartel.cartl_entry_state_name }}</span>
							</div>
						</div>
					</div>
				</div>

				<div
					class="flex-1 flex-column justify-center text-center cursor-pointer"
					@click="toHome"
				>
					<h2>{{ cartel.cartl_name }}</h2>
					<div>{{ cartel.cartl_desctn }}</div>
				</div>

				<div
					class="pa-10 flex-column justify-end text-right"
					style="flex-basis: 220px"
				>
					<div class="level ">{{ cartel.cartl_level_name }}</div>
					<div class="member flex-row justify-end mt-10">

						<div class="badge_30 mr-10 " :class="'badge_30_' + cartel.type + '_bl'">
							<em class="hide">{{ cartel.type }}</em>
						</div>
						<div class="flex-column justify-center">
							<div><img :src="require('@/assets/image/web/home_cartel/ico_interest_influence.svg')" width="25px" /> 멤버수<b> {{  cartel.current_member_count }}</b></div>
						</div>
					</div>

					<div
						v-if="is_join"
						class="mt-10"
					>
						<button
							class="profile_set ml-10 cursor-pointer"
							@click="toSetting"
						>
							<span>설정</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div
			class=" cartel-menu"
		>
			<div
				class="flex-row"
				style="width: 1200px; margin: 0 auto;"
			>
				<div class="flex-row " style="width: 750px">
					<div
						class="mr-20  flex-column justify-center hover-pointer"
						:class="{ on: cartel_position.home }"
						@click="toHome"
					>홈</div>
					<div
						class="mr-20 flex-column justify-center hover-pointer"
						:class="{ on: cartel_position.drops }"
					>NFT DRPOS</div>
					<div
						class="flex-column justify-center"
						style="flex-basis: 550px; overflow: hidden; white-space: nowrap"
					>
						<ul
							class=" wrap-cartel-menu flex-row full-height"
							style="overflow: auto; "
							ref="menu-box"
						>
							<li
								v-for="(menu, m_index) in items_board_config"
								:key="'menu_' + m_index"
								class="mr-20 hover-pointer flex-column justify-center"
								:class="{ on: cartel_position[menu.board_number] }"
								@click="toList(menu)"
							>{{ menu.board_name}}</li>
						</ul>
					</div>
					<div
						style="flex-basis: 50px;"
						class="text-center flex-column justify-center align-center cursor-pointer"
						@click="toScroll"
					>
						<img :src="require('@/assets/image/web/index_cartel/ico_arrow_right_lg.svg')" style="width: 10px">
					</div>
				</div>
				<div class="flex-1 pa-10 ml-20">
					<div class=" flex-row box radius-20">
						<input
							v-model="item_search.search_value"
							class=" pa-10-20 flex-1" placeholder="카르텔 검색"
							@keyup.enter="toSearch"
							maxlength="20"
						/>
						<v-icon
							v-if="item_search.search_value"
							@click="item_search.search_value = ''"
						>mdi-close-circle</v-icon>
						<v-icon
							class="ml-10 mr-10 cursor-pointer"
							@click="toSearch"
						>mdi-magnify</v-icon>
					</div>
				</div>
			</div>
		</div>

		<mafia057
			v-if="is_on_invite"
			:user="user"

			@cancel="is_on_invite = false"
		></mafia057>
	</div>
</template>

<script>

	import Mafia057 from "@/view/Cartel/mafia057";

	export default {
		name: 'CartelTop'
		, props: ['user']
		, components: {Mafia057}
		, data: function(){
			return {
				items_board_config: [

				]
				, item_member_info: {}
				, item_slide: {
					d: 550
					, p: 1
					, doing: false
				}
				, cartel: {

				}
				, is_on_invite: false
				, item_search: {
					search_value: this.$route.params.sarch_value
				}
				, item_permission: {

				}
			}
		}
		, computed:{

			is_join_confirm: function(){
				let t = false
				switch(this.cartel.cartl_entry_state_code) {
					case 'CA00300004':
						t = true
						break
				}
				return t
			}
			, is_join: function(){
				let t = false
				if(this.cartel.cartl_entry_state_code == 'CA00300004'){
					t = true
				}
				return t
			}
			, is_join_cancel: function (){
				let t = false
				switch(this.cartel.cartl_entry_state_code){
					case 'CA00300003':
					case 'CA00300005':
					case 'CA00300006':
					case 'CA00300007':
						t = true
						break;
				}
				return t
			}
			, is_join_wait: function(){
				let t = false
				switch(this.cartel.cartl_entry_state_code){
					case 'CA00300001':
					case 'CA00300002':
						t = true
						break;
				}
				return t
			}
			, is_setting: function(){
				let t = false

				if(this.cartel.cartl_entry_state_code == 'CA00300004'){
					t = true
				}

				t = true

				return t
			}
			, cartel_position: function(){
				let t = {

				}
				if(this.$route.params.b_id){
					t[this.$route.params.b_id] = true
				}else if(this.$route.name == 'drops'){
					t.drops = true
				}else{
					t.home = true
				}

				return t
			}
		}
		, methods: {

			toSetting: function(){

				if(this.item_permission.cartl_member_div_code == 'CA02500001') {
					this.$bus.$emit('to', {name: 'mafia074', params: {idx: this.$route.params.idx, from: 'mafia049'}})
				}else{
					this.$bus.$emit('to', {name: 'mafia076', params: {idx: this.$route.params.idx, from: 'mafia049'}})
				}
			}
			,getCartelMenu: async function(){
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: this.$api_url.api_path.get_cartel_menu
						,data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_member_info = result.data
						this.items_board_config = result.data.board_list

						this.$bus.$emit('setBoardConfigSort', this.items_board_config)
					}else{
						throw result.message
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toScroll: function(){

				if(!this.item_slide.doing){

					this.item_slide.doing = true

					let t = this.$refs["menu-box"].scrollWidth
					let tp = Math.ceil(t / this.item_slide.d)

					let x = this.item_slide.d * this.item_slide.p

					console.log(this.item_slide.d, this.item_slide.p, x)
					let p = 10
					let pp = setInterval(() => {
						if(this.item_slide.p == 0) {
							console.log('finish', p, x)
							clearInterval(pp)
							this.$refs["menu-box"].scrollTo(0, 0)

							this.item_slide.p++
							this.item_slide.doing = false
						}else if(p >= x){
							console.log('finish', p, x)
							clearInterval(pp)

							this.item_slide.p++
							if(this.item_slide.p >= tp){
								this.item_slide.p = 0
							}
							this.item_slide.doing = false
						}else{
							this.$refs["menu-box"].scrollTo((p + (x - this.item_slide.d)), 0)
							p += 10
						}
					}, 10)

				}
			}
			, toList: function(item){
				console.log(item)
				if(item.board_authority.inquiry_fg != 'Y'){
					this.$bus.$emit('notify', { type: 'error', message: '조회권한이 없습니다.'})
				}else{

					if(item.board_type_code === 'CA00700001'){
						this.$bus.$emit('to', { name: 'mafia053', path: '/cartel/mafia053/' + this.$route.params.idx + '/' + item.board_type_code + '/' + item.board_number, params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, from: this.$route.name }, not_query: true})
					}else{
						this.$bus.$emit('to', { name: 'mafia053', path: '/cartel/mafia053/' + this.$route.params.idx + '/' + item.board_type_code + '/' + item.board_number, params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, from: this.$route.name }, not_query: true})
					}
				}
			}
			, getCartel: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.cartel = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toHome: function(){
				this.$bus.$emit('to', { name: 'mafia049', params: {idx: this.$route.params.idx }})
			}
			, onInvite: function(){
				this.is_on_invite = true
			}
			, toSearch: function(){
				if(this.item_search.search_value){
					if(this.item_search.search_value.length < 2){
						this.$bus.$emit('notify', { type: 'error', message: '검색어는 두글자 이상 입력하세요'})
					}else{
						this.$bus.$emit('to', { name: 'mafia048', params: {idx: this.$route.params.idx, search_value: this.item_search.search_value}})
					}
				}else{
					this.$bus.$emit('notify', { type: 'error', message: '검색어를 입력하세요'})
				}

			}
			, getPermission: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_permission
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})
					if(result.success){
						this.item_permission = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}

		, async created() {
			await this.getCartel()
			await this.getCartelMenu()
			if(this.item_member_info.cartl_member_grade_number){
				await this.getPermission()
			}

		}
		,watch: {
			$route(to, from){

				console.log(to, from)

				if(this.cartel_position.home){
					this.item_search.search_value = this.$route.params.search_value
				}else{
					this.item_search.search_value = ''
				}
			}
		}
	}
</script>

<style>
	.cartel-info{ height: 300px}

	.cartel-menu { font-size: 16px;  font-weight: 700 }

	.wrap-cartel-menu::-webkit-scrollbar{
		height: 0px;
		background-color: #333;
	}
	.wrap-cartel-menu::-webkit-scrollbar-thumb {
		background-color: #2f3542;
	}
	.wrap-cartel-menu::-webkit-scrollbar-track {
		background-color: grey;
	}
</style>