import { render, staticRenderFns } from "./App.vue?vue&type=template&id=124fcdb9&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/reset.css?vue&type=style&index=0&lang=css&"
import style1 from "@/assets/css/icomoon.css?vue&type=style&index=1&lang=css&"
import style2 from "@/assets/css/font.css?vue&type=style&index=2&lang=css&"
import style3 from "@/assets/css/style.css?vue&type=style&index=3&lang=css&"
import style4 from "@/assets/css/base.css?vue&type=style&index=4&lang=css&"
import style5 from "./App.vue?vue&type=style&index=5&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports