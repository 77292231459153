<template>
	<div class="header flex-row text-left" >

		<div class="logo">
			<a @click="toMain"><img :src="require('@/assets/image/web/ico_logo-header.svg')" /></a>
		</div>

		<div class="nav">
			<ul>
				<li
					v-for="(gnb, g_index) in list_gnb"
					:key="'gnb_' + g_index"
					:class="{on: gnb.is_on}"
					:data-text="gnb.name"
				><a @click="toGnb(gnb)" class="btn_l">{{ gnb.name }}</a></li>
				<li class="dot"></li>
				<li
					v-for="(gnb, g2_index) in list_gnb2"
					:key="'gnb2_' + g2_index"
					:class="{on: gnb.is_on}"
					:data-text="gnb.name"
				><a @click="toGnb(gnb)" class="btn_l">{{ gnb.name }}</a></li>
			</ul>
		</div>

		<div class="util">
			<div>
				<input
					v-model="item_search.search_value"
					type="text"
					class="input pa-10-20"
					placeholder="전체 검색"
					@keyup.enter="toSearch"
				/>
				<a
					class="ico_alram on" :class="{on: is_notify_on}"
					@click="$emit('to', { name: 'mafia118'})"
				><img :src="require('@/assets/image/ico_alram2.png')"></a>
				<a
					class="ico_profile"
					@click="$router.push({ name: 'myPage'})"
				><img
					v-if="user.profle_nft_card_img_url"
					:src="user.profle_nft_card_img_url"
				/><img
					v-else
					:src="require('@/assets/image/web/mypage_cartel/@profile_inner.png')"
					class="width-100"
				/></a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Header'
		, props: ['user']
		, data: function(){
			return {
				item_notify: {
					nrt_note_receive_fg: 'N'
					, nrt_note_receive_count: 0
				}
				, items_gnb: [
					{ name: '카르텔', to: 'cartel'}
					, { name: 'NFT 컬렉션', to: 'nft'}
					, { name: 'WALLET', to: 'wallet'}
					, { name: 'DROPS', to: 'drops'}
					, { name: 'STAKING', to: 'staking'}
				]
				, items_gnb2: [
					{ name: '공지사항', to: 'notice'}
					, { name: 'FAQ', to: 'faq'}
					, { name: '개설요청', to: 'etc'}
				]
				, item_search: {
					search_value: this.$route.params.search_value
				}
			}
		}
		,computed:{
			is_nft: function(){
				let t = false
				if(this.$route.path.indexOf('nft') > -1){
					t = true
				}
				return t
			}
			, is_wallet: function(){
				let t = false
				if(this.$route.path.indexOf('wallet') > -1){
					t = true
				}
				return t
			}
			, is_staking: function(){
				let t = false
				if(this.$route.path.indexOf('staking') > -1){
					t = true
				}
				return t
			}
			, is_cartel: function(){
				let t = false
				if(this.$route.path == '/' || this.$route.path == '/index' || this.$route.path.indexOf('cartel') > -1){
					t = true
				}
				return t
			}
			, is_bottom: function(){
				let t = true

				if(this.$route.path.indexOf('drops') > -1 || this.is_cartel){
					t = false
				}
				return t
			}
			, is_notify_on: function(){
				let t = false

				console.log('this.item_notify.nrt_note_receive_fg', this.item_notify.nrt_note_receive_fg)
				if(this.item_notify.nrt_note_receive_fg == 'Y'){
					t = true
				}

				return t
			}
			, list_gnb: function(){
				return this.items_gnb.filter((item) => {

					if((this.$route.fullPath).indexOf(item.to) > -1){
						item.is_on = true
					}else{
						item.is_on = false
					}
					return item
				})
			}
			, list_gnb2: function(){
				return this.items_gnb2.filter((item) => {

					if((this.$route.fullPath).indexOf(item.to) > -1){
						item.is_on = true
					}else{
						item.is_on = false
					}
					return item
				})
			}
		}
		, methods: {
			toMain: function(){
				this.$bus.$emit('to', {name: 'main'})
			}
			, getCommNotify: async function(){
				if(!this.user.member_number){
					return false
				}
				try {
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_comm_notify
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})

					if(result.success){
						this.item_notify = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					// this.$bus.$emit('notify', { type: 'error', message: e})
					return false
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toGnb: function(gnb){
				this.$bus.$emit('to', { name: gnb.to})
			}
			, toSearch: function(){
				this.$bus.$emit('to', { name: 'mafia047', params: {search_value: this.item_search.search_value}})
			}
		}
		, created() {
			this.getCommNotify()
		}
		, watch:{
			$route(to){
				console.log('header watch to', to)
				this.item_search.search = to.params.search_value
			}
		}
	}
</script>

<style>
	.header {
		height: 80px;
		overflow: hidden;
		background-color: white;
	}
	.header > div{
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.header .logo{
		padding: 10px;
		flex-basis: 240px;
	}
	.header .nav {
		height: 100%;
		flex: 1;
	}

	.header .nav .dot{
		background-color: #dadada;
		border-radius: 50px;
		width: 2px; height: 2px; padding: 2px;
	}

	.header .nav ul{
		height: 100%;
	}
	.header .nav ul li{
		display: inline-flex;
		padding: 0 10px;
		height: 100%;
	}

	.header .nav ul li:hover,
	.header .nav ul li.on {
		border-bottom: 2px solid #333;
		overflow: hidden;
		text-shadow: 0.3px 0px #333;
	}

	.header .nav ul li a{
		font-size: 16px;
		line-height: 500%;
	}

	.header .util {
		text-align: right;
		flex-basis: 350px;
		padding: 10px
	}

	.header .util .input {
		width: 200px;
		border: 1px solid #bbb;
		border-radius: 50px 50px;
		padding: 10px;
		margin-right: 10px;
	}

	.header .util .ico_alram {
		position: relative;
		margin-right: 10px;
	}
	.header .util .ico_alram img { width: 24px; height: 24px;}

	.header .util .ico_alram.on:after { content: ''; position: absolute; right: 0; top: -.5rem; width: 0.5rem; height: .5rem; border-radius: .5rem; background-color: var(--red); }


	.header .util .ico_profile{
		display: inline-block;
		width: 40px; height: 40px;
		border-radius: 100%; border: 1px solid #ddd;
		overflow: hidden;
		margin-right: 10px;
		padding: 0px;
		background-color: #eee;
		text-align: center;
	}
	.header .util .ico_profile img {
		width: 100%;
		object-fit: cover;
		vertical-align: middle
	}

</style>