<template>
	<div
		:class="wrap" id="wrap"
		class="size-px-16"
	>
    <div class="popup_bg" v-if="is_on_popup"></div>
		<Header
			v-if="program.type != 'auth'"
			:user="user"
			@to="to"
		></Header>

		<div
			:class="program.type == 'auth' ? 'wrap-auth' : 'wrap-container'"
		>
			<Banner
				v-if="is_banner"
				class="wrap-banner"
			></Banner>

			<LayoutAuth
				v-else-if="program.type == 'auth'"
				class="auth-content"
			></LayoutAuth>

			<CartelTop
				v-else-if="is_cartel_top"
				:user="user"
				:cartel="program.cartel"
				class="wrap-banner-cartel bg-white"
			></CartelTop>

			<div
				class="wrap-contents"
				:class="program.bg_contents"
			>
				<div
					class="contents position-relative"
				>
					<router-view
						:Axios="Axios"
						:Notify="Notify"
						:class="bg_router"
						:sample="sample"
						:callBack="returnCallBack"
						:rules="rules"
						:user="user"
						:Base64="Base64"

						@to="to"
						@onLoad="onLoad"
						@pinCheck="is_pin = true"
						@goBack="goBack"
						@onLoading="onLoading"
						@offLoading="offLoading"

						@onPopup="is_on_popup = true"
						@offPopup="is_on_popup = false"
						@changePinType="changePinTypeToLost"

						class="full-height pb-30"
					></router-view>

					<PIN
						v-if="is_pin"
						:user="user"
						:options="pin_option"
						style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 99999;"

						@click="pinCallBack"
						@cancel="offPin"
						ref="pin"
						@lostPinConfirm="lostPinConfirm"
					></PIN>
				</div>
			</div>
		</div>

		<Bottom
			v-if="!program.not_footer && program.type != 'popup'"
			:program="program"

			@to="to"
		></Bottom>

		<OTP
			v-if="is_otp"
			:options="otp_option"
			style="position: fixed; left: 0; top: 0; width: 100%; z-index: 101; background-color: white"

			@click="otpCallBack"
			@cancel="is_otp = false"
		></OTP>

		<Unauthorized
			v-if="is_401"

			:user="user"

			class="layer-popup"
		></Unauthorized>

		<Notify
			:msg="notifyCondition.message"
			:type="notifyCondition.type"
			:config="notifyCondition.config"

			@clearMsg="clearMsg"
		/>

		<Loading></Loading>

		<Viewer
			v-if="is_viewer"
			:user="user"
			:file="item_viewer_file"

			@cancel="is_viewer = false"
		></Viewer>

		<PopupFriend
			:user="user"
		></PopupFriend>

	</div>
</template>

<script>
	import Header from '@/view/Layout/Header'
	import Bottom from "@/view/Layout/Bottom";

	import Notify from '@/components/AlertMsg'

	import PIN from "@/view/Auth/mafia007"
	import Loading from "@/view/Layout/Loading";
	import OTP from "@/view/Auth/mafia111-6"

	import Unauthorized from "@/view/Error/Unauthorized"
	import Banner from "@/view/Layout/Banner";
	import LayoutAuth from "@/view/Layout/LayoutAuth";
	import CartelTop from "@/view/Cartel/CartelTop";
	import Viewer from "@/view/Layout/Viewer";
	import PopupFriend from "@/view/Layout/Popup/PopupFriend";

	export default{
		name: 'Layout'
		,props: ['Axios', 'Notify', 'sample', 'rules', 'user', 'Base64']
		,components: {
			PopupFriend,
			Viewer, CartelTop, LayoutAuth, Banner, Header, Bottom, Notify, PIN, Loading, OTP, Unauthorized }
		,data: function(){
			return {
				program: {
				}
				,notifyCondition: {
					message: '메시지 입니다'
					,type: ''
				}
				,returnCallBack: {

				}
				,is_pin: false
        ,is_on_popup: false
				,pin_option: {
					pin_type: 'check'
					, is_can_cancel: true
				}
				, is_otp: false
				, otp_option: {
					otp_type: 'confirm'
				}
				,is_loading: false
				, is_view : false
				, error_status: ''
				, is_401: false
				, is_viewer: false
				, item_viewer_file: {}
				, is_friend: false
				, item_friend: {

				}
        , lost_pin_item: {

        }
			}
		}
		,computed: {
			wrap: function(){
				let type = 'wrap'
				if(this.program.wrap_type == 'flex'){
					type = 'wrap-flex'
				}
				let location = window.location.href

				if(location.indexOf('auth') > -1){
					type += ' bg-auth'
				}

				return type
			}
			, is_sample: function(){
				let t = false
				if(process.env.VUE_APP_TYPE == 'sample'){
					t = true
				}
				return t
			}
			, bg_router: function(){
				let type = 'bg_dark'
				if(this.program.type == 'mypage'){
					type = ''
				}else if(this.program.type == 'cartel' || this.program.type == 'cartel_sub'){
					type = 'bg_wh'
				}
				return type
			}
			, is_banner: function(){
				let t = false
				if(this.program.name && this.program.type != 'auth' && this.program.type != 'mypage' && this.$route.path.indexOf('/cartel/') < 0){
					t = true
				}
				return t
			}
			, is_cartel_top: function(){
				let t = false
				console.log(this.$route.path)
				if(this.$route.path.indexOf('/cartel/') > -1){
					t = true
				}
				return t
			}
		}
		,methods: {

			clearMsg: function(){
				this.notifyCondition.message = ''
			}
			,to: async function({ name, path, params, query, not_query, hash }){

				document.body.scrollTop = 0

				console.log('to params', name, this.$route.name)

				let router = this.$router.resolve({ name: name, params: params, query: query})

				if(!not_query) {
					localStorage.removeItem('query')
					this.query = {}
				}else{
					this.setQuery()
				}

				// console.log(name, path, router.href)
				await this.$router.push((path ? path : router.href) + (hash ? '#' + hash : '') +'?a=' + Math.random() )

				/*

				if(path){
					await this.$router.push({ path: path, params: params, query: query})
				}else{
					if(name == this.$route.name){

						console.log('same!! to duplicate page ')
						if(params){
							params.name = name
						}else{
							params = {
								name: name
							}
						}

						await this.$router.push({ name: 'DuplicatePage', params: params, query: query})
					}else{
						console.log(' name != ' + name)
						await this.$router.push({ name: name, params: params, query: query}).catch(function(e){
							console.log(e)
						})
					}
				}

				 */
			}
			,setQuery: function(){
				let store = localStorage.getItem('query')
				if(store){
					let query = JSON.parse(store)
					this.query = query
				}
			}
			,onLoad: function(program){
				this.error_status = sessionStorage.getItem('Error')
				if(this.error_status){
					if(this.$route.fullPath.indexOf('/auth/') > -1){
						this.is_401 = false
					}else{
						this.is_401 = true
					}
				}
				// console.log(`onload ${program.name}`, this.error_status, program)

				this.program = program
			}
			,goBack: function(){
				// console.log('goBack', this.program.from)

				if(this.program.from){

					this.$router.push({ name: this.program.from, params: this.program.params}).catch((e) => {
						if(this.program.from == 'mafia049'){
							this.$router.push({ name: 'mafia044'})
						}
						console.log(e)
					})
				}else{
					this.$storage.clearQuery()
					this.query = {}
					this.$router.back()
				}
			}
			, pinCallBack: function(pin_number){
				this.is_pin = false
				this.pin_option.is_can_cancel = true
				this.$bus.$emit('pinCallback', this.pin_option.pin_type, pin_number)
			}
			, otpCallBack: function(){
				console.log('layout otpCallBack !!')
				this.is_otp = false
				this.$bus.$emit('otpCallBack', this.otp_option.otp_type)
			}
			,callBack: function(){
				this.returnCallBack = this.program.callBack
				this.is_pin = false
				this.$bus.$emit('callBack', this.returnCallBack)
			}
			,onLoading: function(){
				this.is_loading = true
			}
			,offLoading: function(){
				setTimeout(() => {
					this.is_loading = false
				}, 300)
			}
			, onPin: function(pin_type){
				if(pin_type == 'set'){
					this.pin_option.is_can_cancel = false
				}else{
					this.pin_option.is_can_cancel = true
				}
				this.pin_option.pin_type = pin_type
				this.is_otp = false
				this.is_pin = true
			}
			, offPin: function(){
				this.is_pin = false
				this.pin_option.pin_type = 'check'
				this.$bus.$emit('pinCancel')
				this.$bus.$off('pinCallback')
			}
			, onOtp: function(otp_type){
				this.otp_option.otp_type = otp_type
				this.is_pin = false
				this.is_otp = true
			}
			, offOtp: function(){
				this.is_otp = false
			}
			, onViewer: function(file){
				this.is_viewer = true
				this.item_viewer_file = file
			}
			, changePinTypeToLost: function(item){
				this.pin_option.pin_type = 'lost'
				this.lost_pin_item = item
				// console.log('lost pin item : ' + JSON.stringify(item))
			}

			, lostPinConfirm: async function(pinnumber = ''){
				await this.$refs.pin.postConfirm(this.lost_pin_item.certfc_number, this.lost_pin_item.certfc_request_key, pinnumber, this.lost_pin_item.mphone_number)
			}
			,clipBoard: function (val){
				const t = document.createElement("textarea");
				document.body.appendChild(t);
				t.value = val;
				t.select();
				document.execCommand('copy');
				document.body.removeChild(t);
			}

			,copy: function (val){
				this.clipBoard(val);
				this.$bus.$emit('notify',  { type: 'success', message: '클립보드에 복사되었습니다.'})
			}

		}
		, created() {
			console.log('on layout')

			this.$bus.$on('onLoad', async (program) => {
				this.onLoad(program)
			})
			this.$bus.$on('to', (to) => {
				console.log('to on bus', this.$route.name)
				this.to(to)
			})
			this.$bus.$on('onPin', (type) => {
				this.onPin(type)
			})
			this.$bus.$on('offPin', () => {
				this.offPin()
			})
			this.$bus.$on('onOtp', (type) => {
				console.log('layout bus on otp')
				this.onOtp(type)
			})
			this.$bus.$on('offOtp', () => {
				this.offOtp()
			})
			this.$bus.$on('viewer', (file) => {
				this.onViewer(file)
			})
			this.$bus.$on('clipboard', (val) => {
				this.copy(val)
			})

			this.$bus.$on('onErrorImage', (e) => {
				e.target.src = require('@/assets/image/web/notify_cartel/ico_voting_none.svg')
				e.target.style.width = '120px'
				e.target.style.padding = '20px'
				e.target.parentElement.style.textAlign='center'
				e.target.parentElement.style.backgroundColor='#eee'
			})
			this.$bus.$on('onErrorVoteImage', (e) => {
				e.target.src = require('@/assets/image/web/notify_cartel/ico_voting_none.svg')
				e.target.style.background='#eee'
			})
			this.$bus.$on('onErrorProfileImage', (e) => {
				e.target.src = require('@/assets/image/web/mypage_cartel/@profile_inner.png')
			})
			window.onPin = () => {
				console.log('window.onPin')
				try{
					if(this.user.member_number){
						this.pin_option.is_can_cancel = false
						this.onPin('check')
					}
				}catch (e) {
					console.log(e)
				}
			}
			window.goBack = () => {
				console.log('window.goBack')
				try{

					let dir = this.$route.name
					console.log('dir: ', dir)
					this.goBack()
				}catch (e) {
					console.log(e)
				}
			}

			if(!window.Kakao.isInitialized()){
				window.Kakao.init(process.env.VUE_APP_KEY_KAKAO_JAVASCIRPT)
			}
		}
	}
	
</script>

<style>
	.wrap {

	}
	.wrap-body {
		width: 100%;
		overflow: hidden;
		text-align: center !important;
		margin: 0 auto;
	}

	.wrap-banner-cartel {

		width: 100%;
		height: 360px;
		overflow: hidden;
	}

	.wrap-banner {
		width: 100%;
		height: 380px;
		overflow: hidden;
	}

	.wrap-container {
		overflow: hidden
	}

	.wrap-contents {
		overflow: hidden;
		background-color: white;
	}

	.wrap-contents .contents {
		width: 1200px;
		text-align: center;
		margin: 0 auto;
	}

	.wrap-auth {
		width: 1200px; height: 100%;
		margin: 0 auto;
		display: flex;
	}

	.wrap-auth .contents {
		width: 500px; min-height: 800px; height: 100%;
		text-align: center;
		margin: 0;
		flex-basis: 500px;
		overflow: hidden;
	}


  .popup_bg {
    opacity: 80%;
    background: black;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100000;
  }
</style>