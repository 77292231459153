<template>
	<div
		class="wrap-bottom bg-bottom"
		>
		<div
			class="container-bottom"
		>
			<div class="logo-bottom">
				<img :src="require('@/assets/image/web/ico_logo_footer.svg')" />
			</div>

			<div class="box-ask mt-20">
				<div>파트너쉽 및 제휴문의: metamafia@metamafi.com</div>
				<div class="mt-10">투자 및 세일 문의: sales@metamafi.com</div>

				<div class="box-etc">
					<span>Meta Mafi 바로가기</span> |
					<span>개인정보처리방침</span>
				</div>
			</div>

			<hr class="mt-30" />

			<div class="copyright">
				Copyrights 2023 © MetaMAFI Ltd. All Rights Reserved.

				<div class="box-sns">

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Bottom'
		, props: ['program']
		, data: function(){
			return {

			}
		}
		,computed:{
			is_nft: function(){
				let t = false
				if(this.$route.path.indexOf('nft') > 0 || this.$route.path.indexOf('wallet') > 0 || this.$route.path.indexOf('staking') > 0){
					t = true
				}
				return t
			}
			,is_drops: function(){
				let t = false
				if(this.$route.path.indexOf('drops') > 0){
					t = true
				}
				return t
			}
			,is_cartel: function(){
				let t = false

				if(this.$route.path == '/' || this.$route.path == '/index' || this.$route.path.indexOf('cartel') > 0){
					t = true
				}
				return t
			}
		}
		, methods: {

			to: function(type){
				if(this.$route.name != type){
					this.$emit('to', { name: type})
				}
			}
		}
		,created() {
			//console.log('bottom', this.program)
		}
	}
</script>

<style>
	.bg-bottom {
		background-color: #f7f7f7;
	}
	.container-bottom {
		width: 1200px; margin: 0 auto;
		padding: 40px 0 0;
		text-align: left !important;
		position: relative;
	}

	.container-bottom .box-ask {
		position: relative;
	}
	.container-bottom .box-ask .box-etc{
		position: absolute;
		right: 0; bottom: 0px;
		color: #434343;
		font-size: 12px; font-weight: 500;
	}

	.container-bottom hr {
		border-top: 1px solid #DADADA
	}

	.container-bottom .copyright{
		padding: 50px 0;
	}
</style>