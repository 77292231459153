<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character3">
							<div class="text_guide pt-20">
								<h4> {{ title }}</h4>
							</div>
							<div class="text_guide_desc pt-15">
								<p v-html="content"></p>
							</div>
						</div>

					</div>
					<div class="intro_btn_wrap flex-row justify-space-between">
						<div
							v-if="is_update"
							class="btn_area flex-1"
						>
							<button
								@click="doUpdate"
								class="btn_l btn_fill_blue"
							>업데이트</button>
						</div>
						<div
							v-else-if="is_exit"
							class="btn_area flex-1"
						>
							<button
								@click="toExit"
								class="btn_l btn_fill_gray"
							>앱 종료</button>
						</div>
						<div
							v-else
							class="btn_area flex-1"
						>
							<button
								@click="toNext"
								class="btn_l btn_fill_blue"
							>확인</button>
						</div>
						<div
							v-if="is_cancel"
							class="btn_area flex-1 ml-10"
						>
							<button
								@click="toCancel"
								class="btn_l btn_fill_gray"
							>나중에</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'version'
	, props: ['type']
	,data: function(){
		return {
			program: {
				name: '앱 버전 확인'
				, not_header: true
				, not_footer: true
			}
		}
	}
	, computed: {
		title: function(){
			let t = ''

			switch (this.type){
				case 'AP00500002':
					t = '메타마피아 안내'
					break;
				case 'AP00500003':
					t = '메타마피아 안내'
					break;
				case 'AP00500004':
					t = '메타마피아 안내'
					break;
				case 'AP00500005':
					t = '메타마피아 안내'
					break;
				case 'AP00500006':
					t = '메타마피아 안내'
					break;
				default:
					t = '메타마피아 안내'
					break;
			}

			return t
		}
		, content: function(){
			let t = ''

			switch (this.type){
				case 'AP00500002':
					t = '앱 공지입니다'
					break;
				case 'AP00500003':
					t = '현재는 메타마피아 앱을 이용할 수 없습니다'
					break;
				case 'AP00500004':
					t = '새로운 버전이 출시 되었습니다.'
					break;
				case 'AP00500005':
					t = '새로운 버전이 출시 되었습니다. <br/><br/> 업데이트 이후에 이용가능합니다.'
					break;
				case 'AP00500006':
					t = '새로운 업데이트가 있습니다.'
					break;
			}

			return t
		}
		, is_cancel: function(){
			let t = false
			switch (this.type){
				case 'AP00500004': case 'AP00500006':
					t = true
					break;
			}
			return t
		}
		, is_exit: function(){
			let t = false
			switch (this.type){
				case 'AP00500003':
					t = true
					break;
			}
			return t
		}
		, is_update: function(){
			let t = false
			switch (this.type){
				case 'AP00500004': case 'AP00500005': case 'AP00500006':
					t = true
					break;
			}
			return t
		}
	}
	,methods: {
		toNext: function(){
			this.$emit('cancel')
		}
		, doUpdate: function(){
		}
		, toCancel: function(){
			this.$emit('cancel')
		}
		, toExit: function(){
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
	}
}
</script>

<style>
	.flex-row { display: flex; flex-direction: row}
	.justify-space-between { justify-content: space-between}
	.flex-1 { flex: 1}
</style>