<template>
	<PopupConfirm
		v-if="is_use"
		@click="postFriend"
		@cancel="is_use = false"
		class="text-center"
	>
		<template
			v-slot:title
		>친구 {{ title_text }}</template>
		<template
			v-slot:main-txt
		>친구 {{ title_text }} 하시겠습니까?</template>
		<template
			v-slot:sub-txt
		>친구 {{ title_text }} 하시려면 확인을 눌러주세요.<br/>
			<div
				v-if="!is_friend"
				class="mt-10"
			>
				<input v-model="is_notify" type="checkbox" id="mdChk">
				<label for="mdChk"> 친구의 새글 알림을 받을까요?</label>
			</div>
		</template>
	</PopupConfirm>
</template>

<script>
	import PopupConfirm from "@/view/Layout/PopupConfirm";
	export default {
		name: 'PopupFriend'
		, props: ['user']
		, components: {PopupConfirm}
		, data: function(){
			return {
				is_notify: true
				, is_use: false
				, item_friend: {
					type: false
				}
				, confirm_type: false
			}
		}
		, computed: {

			title_text: function(){
				let t = ''
				if(this.confirm_type){
					t = '추가'
				}else{
					t = '취소'
				}
				return t
			}
			, is_friend: function(){
				let t = false
				if(this.item_friend.member_friend_fg == 'Y'){
					t = true
				}
				return t
			}
		}
		, methods: {

			postFriend: async function(){
				let url = this.$api_url.api_path.post_friend_add
				if(this.is_friend){
					url = this.$api_url.api_path.post_friend_cancel
				}
				try {
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, friend_member_number: this.item_friend.member_number
							, note_fg: this.is_notify ? 'Y' : 'N'
						}
						, type: true
					})

					console.log('postFriend result ', result)
					if(result.success){
						this.$bus.$emit('callBack', {
							type: 'postFriend'
							, result: true
						})
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)

					this.$bus.$emit('callBack', {
						type: 'postFriend'
						, result: false
						, message: e
					})
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_use = false
				}
			}
		}
		, created() {
			// console.log('this.user', this.user)

			this.$bus.$on('onFriend', (e) => {
				console.log('onFriend', e)

				this.is_use = true
				this.confirm_type = e.type
				this.item_friend = e.item_friend
			})
		}
	}
</script>