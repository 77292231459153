
// import axios from 'axios'
export const common = {

    isMobile: function(){
        let user = navigator.userAgent;
        let w_type = 'web'
        w_type = 'CM00500002'
        if(user.toLowerCase().indexOf('iphone') > -1 || user.toLowerCase().indexOf('ipad') > -1 || user.toLowerCase().indexOf('ipod') > -1 || user.toLowerCase().indexOf('android') > -1){
            w_type = 'mobile'
            w_type = 'CM00500003'
        }

        return w_type
    }
    , getMobile: function(){
        let user = navigator.userAgent;
        let w_type = 'web'
        w_type = 'CM00500002'
        if(user.toLowerCase().indexOf('iphone') > -1 || user.toLowerCase().indexOf('ipad') > -1 || user.toLowerCase().indexOf('ipod') > -1){
            w_type = 'ios'
        }else if(user.toLowerCase().indexOf('android') > -1){
            w_type = 'android'
        }

        return w_type
    }
    , getIp: async function(){
        return ''
        /*
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            if(response){
                throw response.data.ip
            }else{
                throw 'error'
            }
        } catch (error) {
            console.error(error);
            return 'error'
        }
        */
    }
}