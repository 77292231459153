<template>
	<div
		class="wrap-banner"
	>
		<ul>
			<li
				v-for="(banner, b_index) in items_banner"
				:key="'banner_' + b_index"
			>
				<img
					v-if="banner.url"
					:src="banner.url" :alt="banner.name"
				/>
				<img
					v-else
					:src="require('@/assets/image/web/index_cartel/bg_banner01.jpg')"
					:alt="banner.name"
				/>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'banner'
		, props: ['user']
		, data: function(){
			return {

				items_banner: [
					{
						name: ''
						, url: ''
					}
					, {
						name: ''
						, url: ''
					}
				]
			}
		}
	}
</script>