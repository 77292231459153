<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class="width-320 bg-white  ma-auto radius-20">
				<div class="justify-space-between under-line pa-20">
					<h3>OTP 인증</h3>
					<v-icon @click="$emit('cancel')">mdi-close-circle</v-icon>
				</div>

				<div class="pa-20">
					<div class="">
						<label for="tit otpNum" class="size-px-16">OTP 인증번호</label>
						<div class="mt-20">
							<input
								v-model="gogle_otp_certfc_number"
								type="text" id="otpNum"
								placeholder="6자리 인증번호 입력"
								maxlength="6"
								class="input-box pa-10-20 radius-20"
							>
						</div>
					</div>

					<div class="text-right mt-10">
						<a
							v-if="is_otp_find"
							class="size-px-12"
							@click="toOtpChange"
						>OTP를 사용할 수 없으신가요?<v-icon>mdi-chevron-right</v-icon></a>
					</div>

					<div class="mt-30 text-center">
						<button class="btn-inline btn-primary radius-20" :disabled="gogle_otp_certfc_number.length < 6" @click="postOtp">확인</button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>

	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'mafia1116'
		,
		components: {PopupLayer},
		props: ['user', 'options']
		, data: function(){
			return {
				gogle_otp_certfc_number: ''
			}
		}
		, computed: {
			otp_type: function(){
				let type = 'set'

				if(this.options){
					type = this.options.otp_type
				}else if(this.$route.params.otp_type){
					type = this.$route.params.otp_type
				}

				console.log('otp_type', type)
				return type
			}
			, is_otp_find: function(){
				let t = true
				if(this.otp_type == 'pin_confirm'){
					t = false
				}
				return t
			}
		}
		, methods: {
			postOtp: function(){
				if(this.otp_type == 'pin_confirm') {
					this.postPinOtp()
				}else if(this.otp_type == 'check'){
					this.$emit('click', this.gogle_otp_certfc_number)
				}else{
					this.postOtpAdd()
				}
			}
			, postOtpAdd: async function(){
				try {
					if(!this.gogle_otp_certfc_number){
						throw 'OTP 인증번호를 입력하세요'
					}
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_google_otp
						, data: {
							gogle_otp_certfc_number: this.gogle_otp_certfc_number
						}
						, type: true
					})

					if(result.success){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
						this.$emit('click')
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, postPinOtp: async function(){
				try {
					if(!this.gogle_otp_certfc_number){
						throw 'OTP 인증번호를 입력하세요'
					}
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_otp_for_pin
						, data: {
							gogle_otp_certfc_number: this.gogle_otp_certfc_number
						}
						, type: true
					})

					if(result.success){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
						this.$emit('click')
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, toOtpChange: function(){
				this.$emit('cancel')
				this.$bus.$emit('to', { name: 'mafia1113'})
			}
		}

	}
</script>