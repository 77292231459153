<template>
	<div
		class="position-fixed"
		style="left: 0; top: 0; width: 100%; height: 100%; overflow: hidden; z-index: 9999"
	>
		<div class="bg-layer"></div>
		<div
			class="position-relative viewer pa-20 flex-column justify-center"
			style="width: 100%; height: 100%;"
		>
			<div
				style="min-width: 320px; max-width: 95%; min-height: 480px; margin: 0 auto;"
				class="flex-column "
			>
				<div class="pa-10 bg-primary flex-row justify-space-between">
					첨부이미지
					<button
						@click="$emit('cancel')"
					><v-icon class="color-white">mdi-close-circle-outline</v-icon></button>
				</div>
				<div
					class="items-center bg-gray text-center justify-center flex-column full-height"
					style="overflow: auto"
				>
					<img
						v-if="is_image"
						:src="file.post_file_url"

						@error="$bus.$emit('onErrorImage', $event)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Viewer'
		, props: ['uer', 'file']
		, computed: {
			is_image: function(){
				let t = false
				if(!this.file.post_appendix_file_div_code || this.file.post_appendix_file_div_code == 'CA01000001'){
					t = true
				}

				return t
			}
		}
		, created() {
			console.log(this.file)
		}
	}
</script>