<template>
	<div>
		<div class="t1">
			메타마피아 재단 바로가기 &gt;
		</div>
		<div class="mt-20 t2">
			문화예술 전용 커뮤니티
		</div>
		<div class="mt-20 t3">
			마피아 프로젝트
		</div>

		<div class="mt-20 t4">
			셀럽과의 소통 공간에서 셀럽의 NFT를 구매해보세요
		</div>

		<div class="mt-30 t5">
			<div class="bg-white pa-10 radius-10 mr-10"><img :src="require('@/assets/image/web/intro/ico_qrcode.svg')" /></div>
			<div>
				<dl>
					<dt>마피아 프로젝트 APP</dt>
					<dd class="mt-10">마피아 프로젝트 앱으로 편리하게 이용가능합니다.</dd>
					<dd>지금 즉시 나만의 셀럽을 만나보세요</dd>
				</dl>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'LayoutAuth'
		, props: ['user']
		, data: function(){
			return {

			}
		}
	}
</script>