
export const api_url = {
    api_path: {
        // COM-01-001 앱 인증
        post_device_token: 'mafiaa/b/appcertfc'
        // COM-05-001 공통코드조회
        , get_common_code: 'mafiaa/com/commoncodeinquiry'
        // 약관 조회
        , get_agreement: 'mafiaa/mem/memberstiplist'
        // 공통 코드
        // 구글 OTP 키 조회
        , get_google_otp_key: 'mafiaa/mem/gogleotpkeyinquiry'
        // 구글 OTP 변경 키 조회
        , get_google_otp_change_key: 'mafiaa/mem/gogleotpchgkeyinquiry'
        // 구글 OTP 등록
        , post_google_otp: 'mafiaa/mem/gogleotpcertfc'
        // MEM-21-004 구글 OTP 변경 인증
        , post_google_change_otp: 'mafiaa/mem/gogleotpchgcertfc'
        // MEM-04-003 핀번호 저장 요청
        , post_pin: 'mafiaa/mem/pinnumbersave/::1.1.0'
        // MEM-04-006	휴대폰SMS 인증번호 요청
        , post_sms_request: 'mafiaa/mem/mphonecertfcnumberrequest/::1.1.0'
        // MEM-04-007	휴대폰SMS 인증번호 확인
        , post_sms_confirm: 'mafiaa/mem/mphonecertfcnumberconfirmation/::1.1.0'
        // MEM-05-002 로그인 핀번호 확인 요청
        , post_pin_login: 'mafiaa/mem/loginpinnumberconfirmation'
        // MEM-22-003 핀번호 분실 저장
        , post_pin_befor_login: 'mafiaa/mem/pinnumberlostsave'
        // MEM-22-004	핀번호 분실 휴대폰SMS 인증번호 요청
        , post_pin_lost: 'mafiaa/mem/pinnumberlostmphonecertfcnumberrequest'
        // MEM-17-001 핀 번호 변경 핀 인증
        , post_pin_check: 'mafiaa/mem/pinnumberchgpincertfc'
        // MEM-21-002 구글 OTP 변경 핀번호 확인
        , post_otp_pin_check: 'mafiaa/mem/gogleotpchgpinnumberconfirmation'
        // MEM-17-002 핀번호 변경 저장
        , post_pin_update: 'mafiaa/mem/pinnumberchgsave'
        // 관심분야 저장
        , post_concernsphere: 'mafiaa/mem/concernspheresave/::1.1.0'
        // 닉네임 저장
        , post_account: 'mafiaa/mem/nicknamesave/::1.1.0'
        // NFT 카드 목록 - 전체
        , API_PATH_NFT_LIST: '/'
        // NFT-05-001 NFT 메인 정보 조회
        , get_main_nft: 'mafiaa/nft/nftmaininfoinquiry'
        // 메인 NFT 업데이트
        , update_main_nft: 'mafiaa/nft/proflenftcardsave'
        // NFT-07-001 Klaytn NFT 거래 요청
        , post_nft_withdrawal: 'mafiaa/nft/nfttransactionrequest'
        // NFT-07-002	카르텔 NFT 거래 요청
        , post_cartel_nft_withdrawal: 'mafiaa/nft/cartlnfttransactionrequest'
        // NFT-10-001 카르텔 NFT 상세 정보 조회
        , get_nft_card_info: 'mafiaa/nft/cartlnftdetilinfoinquiry'
        // 내 친구 목록
        , get_friends_list: 'mafiaa/mem/friendlistinquiry'
        // 통화 목록
        , get_currency: '1'
        // WLT-02-001 회원 지갑 목록 조회
        , get_wallet_list: 'mafiaa/wlt/memberwalletlistinquiry'
        // 지갑 생성 요청
        , post_wallet: 'mafiaa/wlt/walletcreation'
        // 지갑 주소 조회
        , get_wallet_address: 'mafiaa/nft/klaytnaddressinfoinquiry'
        // 출금 수수료 조회
        , get_trans_commis: 'mafiaa/com/transactiontranmsncommisinquiry'
        // 출금 요청
        , post_withdrawal: 'mafiaa/wlt/walletwithdrawalrequest'
        // 입출금 내역
        , get_wallet_history: 'mafiaa/wlt/memberwalletdeptwidallistinquiry'
        // DRP-01-001 마피아 NFT 카드 판매 정보 조회
        , get_drops_config: 'mafiaa/drp/mafinftcardslinfoinquiry'
        // DRP-01-004 마피아 NFT 카드 법정화폐 구매 요청
        , post_drops_regal: 'mafiaa/drp/mafinftcardleglterpurchase'
        // DRP-01-005  마피아 NFT 카드 법정화폐 구매 정보 조회
        , get_drops_regal_list: 'mafiaa/drp/mafinftcardleglterpurchaseinfoinquiry'
        // DRP-02-001 마피아 NFT 카드 구매 요청
        , post_nft_drop: 'mafiaa/drp/mafinftcardpurchase'
        // DRP-03-001 유틸리티 NFT 카드 판매 정보 조회
        , get_drops_config_utility: 'mafiaa/drp/utiltynftcardslinfoinquiry'
        // DRP-04-001 유틸리티 NFT 카드 구매 요청
        , post_utility_drop: 'mafiaa/drp/utiltynftcardpurchase'
        // DRP-05-001	판매 NFT 카드 목록 조회
        , get_sales_card_list: 'mafiaa/drp/slnftcardlistinquiry'
        // DRP-05-002	판매 NFT 카드 정보 조회
        , get_sales_card_info: 'mafiaa/drp/slnftcardinfoinquiry'
        // DRP-06-001	 Klaytn 판매 NFT 카드 구매 요청
        , post_sales_card: 'mafiaa/drp/slnftcardpurchase'
        // 구매 가능한 카드 수량 요청
        , get_purchase_nft: '/'
        // 내 NFT 카드 목록
        , get_my_card: '12'
        // 내 유틸리티 카드 목록
        , get_utility_list: 'mafiaa/nft/memberutiltynftcardlistinquiry'
        // 유틸리티 카드 장착 해제
        , post_utility_flag: 'mafiaa/nft/memberutiltynftcardreg'
        // 스테이킹 메인 정보
        , get_staking_main: 'mafiaa/stk/stakgmaininfoinquiry'
        // 스테이킹 카드 목록
        , get_staking_nft: 'mafiaa/stk/stakgnftlistinquiry'
        // STK-06-001 스테이킹 보상 인출 정보 조회
        , get_staking_reward_info: 'mafiaa/stk/stakgrewardwthdwlinfoinquiry'
        // STK-06-002 스테이킹 보상 인출 요청
        , post_staking_reward: 'mafiaa/stk/stakgrewardwthdwlrequest'
        // STK-06-003 스테이킹 보상 인출 내역 조회
        , get_staking_reward: 'mafiaa/stk/stakgrewardwthdwlparticularsinquiry'
        // 마이닝 내역
        , get_mining_list: 'mafiaa/stk/stakghistorylistinquiry'
        // STK-01-001 Klaytn 스테이킹 요청
        , post_staking: 'mafiaa/stk/stakgrequest'
        // STK-02-001 Klaytn 스테이킹 해제
        , post_unstaking: 'mafiaa/stk/stakgrelease'
        // MEM-07-002 NFT 카드 목록 조회
        , get_nft: 'mafiaa/mem/nftcardlistinquiry'
        // NFT-02-001 Klaytn 마피아 NFT 상세 정보 조회 -> 미사용 예정
        , get_nft_detail: 'mafiaa/nft/klaytnmafinftdetilinfoinquiry'
        // 유틸리티 카드 정보
        , get_utility_detail: 'mafiaa/nft/utiltynftinfoinquiry'
        // NFT-06-001 선물 요청
        , post_present: 'mafiaa/nft/giftrequest'
        // NFT-02-002 선물 목록 조회
        , get_present_history: 'mafiaa/nft/giftlistinquiry'
        // NFT-02-003 NFT 거래 목록 조회
        , get_nft_history: 'mafiaa/nft/nfttransactionlistinquiry'
        // 카카오 REST API - 인가 코드 요청
        // 자동 로그인 요청
        , post_auto_login: 'post_auto_login'
        , post_kakao_login: '/'
        // MEM-02-001 소셜 인증 요청
        , post_join_social: 'mafiaa/mem/socialcertfc/::1.1.0'
        // 카카오 REST API - 토큰 요청
        , post_kakao_auth_token: 'https://kauth.kakao.com/oauth/token'
        // 카카오 API - 사용자 정보 요청
        , get_kakao_user_me: 'https://kapi.kakao.com/v2/user/me'
        // MEM-07-003 프로필 정보 저장 요청
        , post_profile: 'mafiaa/mem/profleinfosave'
        // MEM-07-004	프로필 마피아 카드 설정
        , post_profile_set: 'mafiaa/mem/proflemaficardsettup'
        // MEM-15-001 공지사항 목록 조회
        , get_notice_list: 'mafiaa/mem/noticemttrslistinquiry'
        // MEM-15-002 공지사항 정보 조회
        , get_notice_info: 'mafiaa/mem/noticemttrsinfoinquiry'
        // FAQ 카테고리 목록
        , get_faq_category: 'mafiaa/mem/faqcatgylistinquiry'
        // FAQ 목록
        , get_faq_list: 'mafiaa/mem/faqlistinquiry'
        // FAQ 정보 조회
        , get_faq_info: 'mafiaa/mem/faqinfoinquiry'
        // CART-03-001 카르텔 메인 정보 조회
        , get_cartel_main_info: 'mafiaa/cart/cartlmaininfoinquiry/::1.1.0'
        // 카르텔 목록
        , get_cartel_list: '/'
        // CART-04-001	카르텔 정보 조회
        , get_cartel_info: 'mafiaa/cart/cartlinfoinquiry/::1.1.0'
        // CART-04-002	게시물 목록 조회
        , get_cartel_bbs_list: 'mafiaa/cart/bulletinarticleslistinquiry'
        // CART-04-003	카르텔 메뉴 조회
        , get_cartel_menu: 'mafiaa/cart/cartlmenuinquiry/::1.1.0'
        // CART-04-004	카르텔 메뉴 즐겨찾기 등록/해제 요청
        , post_fav: 'mafiaa/cart/cartlmenubookmarkreg/::1.1.0'
        // COM-05-004	첨부 파일 등록 (multipart/form-data)
        , post_file: 'mafiaa/com/appendixfilereg'
        // 카르텔 생성
        , post_cartel: 'mafiaa/cart/cartlregrequest'
        // CART-23-001	 카르텔 수정 요청
        , post_cartel_modify: 'mafiaa/cart/cartlchange/::1.1.0'
        // CART-23-002	 카르텔 가입 승인 확인
        , get_cartel_approval: 'mafiaa/cart/cartlentryapprovalconfirmation/::1.1.0'
        // 카르텔 활동정보 공개여부
        , post_cartel_activity: 'mafiaa/mem/activityinforeleasesettup'
        // 가입된 카르텔 목록
        , get_my_cartel: '3'
        // 추천 카르텔 목록
        , get_cartel_recomm_list: 'mafiaa/cart/recomcartllistinquiry'
        // 공지사항 알람 설정
        , post_notice_alram: 'mafiaa/mem/noticemttrsnotesettup'
        // 스테이킹 알람 설정
        , post_staking_alram: 'mafiaa/mem/stakgparticularsnotesettup'
        // CART-19-001	공지사항 목록 조회
        , get_cartel_notice_list: 'mafiaa/cart/noticemttrslistinquiry/::1.1.0'
        // CART-19-002	 공지사항 정보 조회
        , get_cartel_notice_info: 'mafiaa/cart/noticemttrsinfoinquiry/::1.1.0'
        // CART-20-001	한줄응원 목록 조회
        , get_cartel_cheer_list: 'mafiaa/cart/onelincheerlistinquiry/::1.1.0'
        // CART-20-002	한줄응원 정보 조회
        , get_cartel_cheer_info: 'mafiaa/cart/onelincheerinfoinquiry/::1.1.0'
        // CART-17-008 게시판 게시물 목록 조회
        , get_board_bbs_list: 'mafiaa/cart/boardpostlistinquiry/::1.1.0'
        // CART-07-001	카르텔 탈퇴 요청
        , post_cartel_out: 'mafiaa/cart/cartlwithddt/::1.1.0'
        // CART-08-001	카르텔 폐쇄 요청
        , post_cartel_closure: 'mafiaa/cart/cartlclose/::1.1.0'
        // CART-01-001	카르텔 메인 통합 검색
        , get_cartel_main_search: 'mafiaa/cart/cartlmainunifysearch/::1.1.0'
        // CART-02-001	카르텔 통합 검색
        , get_cartel_total_search: 'mafiaa/cart/cartlunifysearch/::1.1.0'
        // CART-02-002	카르텔 회원 검색
        , get_member_list: 'mafiaa/cart/cartlmembersearch/::1.1.0'
        // CART-02-002	카르텔 회원 검색
        , get_cartel_member_search_list: 'mafiaa/cart/cartlmembersearch/::1.1.0'
        // CART-02-003	카르텔 게시물 검색
        , get_cartel_bbs_search: 'mafiaa/cart/cartlpostsearch/::1.1.0'
        // CART-11-001	 승인 대기 회원 목록 조회
        , get_cartel_member_wait_list: 'mafiaa/cart/approvalwaitingmemberinquiry/::1.1.0'
        // CART-11-002	 카르텔 회원 목록 조회
        , get_cartel_member_list: 'mafiaa/cart/cartlmemberlistinquiry/::1.1.0'
        // CART-11-003	 카르텔 회원 승인 요청
        , post_cartel_member_confirm: 'mafiaa/cart/cartlmemberapproval/::1.1.0'
        // CART-11-004	 카르텔 회원 승인 삭제 요청
        , post_cartel_member_cancel: 'mafiaa/cart/cartlmemberapprovaldelete/::1.1.0'
        // CART-11-005	 카르텔 회원 정지 요청
        , post_cartel_member_stop: 'mafiaa/cart/cartlmemberstop/::1.1.0'
        // CART-11-006	 카르텔 회원 강제탈퇴 요청
        , post_cartel_member_drop: 'mafiaa/cart/cartlmembercompulsorywithddt/::1.1.0'
        // CART-12-001	 카르텔 정지 회원 목록 조회
        , get_cartel_member_stop_list: 'mafiaa/cart/cartlstopmemberinquiry/::1.1.0'
        // CART-12-002	 카르텔 정지 회원 해제 요청
        , post_cartel_member_stop_cancel: 'mafiaa/cart/stoprelease/::1.1.0'
        // CART-03-003 카르텔 가입 요청
        , post_cartel_join: 'mafiaa/cart/cartlentryrequest/::1.1.0'
        // 카르텔 맴버 상태 변경
        , post_cartel_member_status: '4'
        // CART-10-001	게시판 목록 조회
        , get_board_config_list: 'mafiaa/cart/boardlistinquiry/::1.1.0'
        // CART-10-002	게시판 등록 요청
        , post_board_config: 'mafiaa/cart/boardreg/::1.1.0'
        // CART-10-003	게시판 수정 요청
        , post_board_config_modify: 'mafiaa/cart/boardchange/::1.1.0'
        // CART-10-004	게시판 삭제 요청
        , post_board_config_delete: 'mafiaa/cart/boarddelete/::1.1.0'
        // CART-10-005	게시판 권한 정책 조회
        , get_board_config: 'mafiaa/cart/boardinfoinquiry/::1.1.0'
        // CART-10-006	게시판 목록 순번 수정 요청
        , post_board_sort: 'mafiaa/cart/boardlistindexnumberchangerequest/::1.1.0'
        //
        , get_board_permission: 'mafiaa/cart/boardauthoritypolicyinquiry/::1.1.0'
        // 카르텔 초대 목록
        , get_cartel_invite: 'mafiaa/cart/cartlinvitationmemberlistinquiry'
        // CART-05-002	 카르텔 초대 요청
        , post_invite: 'mafiaa/cart/cartlinvitationrequest'
        //친구 알람
        , post_friend_alarm: 'mafiaa/com/friendnwpostnotesettup'
        // CART-09-001	카르텔 언더보스 대상자 조회
        , get_cartel_under_boss: 'mafiaa/cart/cartlundrbostargeterinquiry'
        // CART-09-002	카르텔 언더보스 등록 요청
        , post_cartel_appoint: 'mafiaa/cart/cartlundrbosreg'
        // CART-09-003	카르텔 언더보스 해임 요청
        , post_cartel_fire: 'mafiaa/cart/cartlundrbosdismsal'
        // CART-09-004	카르텔 언더보스 권한 정책 조회
        , get_cartel_underboss_permission: 'mafiaa/cart/cartlundrbosauthoritypolicyinquiry'
        // CART-09-005	카르텔 언더보스 권한 저장
        , post_cartel_permission: 'mafiaa/cart/cartlundrbosauthoritysave'

        // CART-09-009	카르텔 운영자 대상자 조회
        , get_cartel_manager: 'mafiaa/cart/cartloperatingtargeterinquiry'
        // CART-09-010	카르텔 운영자 등록 요청
        , post_cartel_manager_appoint: 'mafiaa/cart/cartladminreg'
        // CART-09-011	카르텔 운영자 해임 요청
        , post_cartel_manager_fire: 'mafiaa/cart/cartladmindismsal'
        // CART-09-012	카르텔 운영자 권한 정책 조회
        , get_cartel_manager_permission: 'mafiaa/cart/cartladminauthoritypolicyinquiry'
        // CART-09-013	카르텔 운영자 권한 저장
        , post_cartel_manager_permission: 'mafiaa/cart/cartladminauthoritysave'

        // CART-19-003	 공지사항 등록 요청
        , post_notice: 'mafiaa/cart/noticemttrsreg/::1.1.0'
        // CART-19-004	 공지사항 수정 요청
        , post_notice_modify: 'mafiaa/cart/noticemttrschange/::1.1.0'
        // CART-20-003	한줄응원 등록 요청
        , post_cheer: 'mafiaa/cart/onelincheerreg/::1.1.0'
        // CART-20-004	한줄응원 수정 요청
        , post_cheer_modify: 'mafiaa/cart/onelincheerchange/::1.1.0'

        // CART-17-002	게시물 정보 조회
        , get_bbs: 'mafiaa/cart/postinfoinquiry/::1.1.0'

        // CART-20-005	한줄응원 삭제 요청
        , post_delete_cheer: 'mafiaa/cart/onelincheerdelete/::1.1.0'
        // CART-19-005	 공지사항 삭제 요청
        , post_delete_notice: 'mafiaa/cart/noticemttrsdelete/::1.1.0'
        // CART-17-009 게시물 신고 요청
        , post_report_bbs: 'mafiaa/cart/postdeclarationrequest/::1.1.0'

        // MEM-06-001 마이페이지 메인 정보 조회
        , get_mypage_main_info: 'mafiaa/mem/mypagmaininfoinquiry'
        // MEM-07-001 회원 정보 조회
        , get_my_info: 'mafiaa/mem/mypagmemberinfoinquiry'
        // MEM-11-001  공지글 알림 카르텔 목록 조회
        , get_my_cartel_notice_alram: 'mafiaa/mem/noticenotecartllistinquiry'
        // MEM-12-002	댓글 알림 카르텔 목록 조회
        , get_my_cartel_comment_alram: 'mafiaa/mem/commentnotecartllistinquiry'
        // MEM-24-001 결제수단 목록 조회
        , get_credit_list: 'mafiaa/mem/paymentmthdlistinquiry'
        // MEM-24-002 신용카드 등록
        , post_credit: 'mafiaa/mem/creditcardreg'
        // MEM-24-003 신용카드 삭제
        , post_credit_delete: 'mafiaa/mem/creditcarddelete'
        // MEM-24-004 대표 결제 수단 설정
        , post_credit_setup: 'mafiaa/mem/representationpaymentmthdsettup'
        // 핀 설정
        , update_pin: '5'
        // COM-02-003 친구 추가
        , post_friend_add: 'mafiaa/com/friendadd'
        // COM-02-004 친구 삭제
        , post_friend_cancel: 'mafiaa/com/frienddelete'
        // 카르텔 통계
        , get_cartel_stat: '6'
        // 카르텔 통계 - 우수멤버
        , get_member_rank: '7'
        // CART-16-001	공감 등록/수정 요청
        , post_emoji: 'mafiaa/cart/postsympatyregchange/::1.1.0'
        // CART-16-002	공감 목록 조회
        , get_emoji_list: 'mafiaa/cart/postsympatyinquiry/::1.1.0'
        // CART-22-001	게시물 투표 요청
        , post_vote: 'mafiaa/cart/postvote'
        // 투표내역
        , get_vote_list: '8'
        // COM-02-001 회원 정보 조회
        , get_member_info: 'mafiaa/com/memberinfo/::1.1.0'
        // 멤버 차단 요청
        , post_black_add: 'mafiaa/com/memberblok'
        // 멤버 차단 해제 요청
        , post_black_cancel: 'mafiaa/mem/blokmemberreleasesettup'
        // 멤버 차단 목록
        , get_black_list: 'mafiaa/mem/blokmemberlistinquiry'
        // CART-15-001	댓글 목록 조회
        , get_bbs_comment_list: 'mafiaa/cart/postcommentlistinquiry/::1.1.0'
        // CART-15-002	댓글 등록 요청
        , post_comment: 'mafiaa/cart/postcommentreg/::1.1.0'
        // CART-15-003	댓글 수정 요청
        , post_comment_modify: 'mafiaa/cart/postcommentchange/::1.1.0'
        // CART-15-004	댓글 삭제 요청
        , delete_comment: 'mafiaa/cart/postcommentdelete/::1.1.0'
        // CART-15-005	댓글 신고 요청
        , post_report_comment: 'mafiaa/cart/postcommentdeclaration/::1.1.0'
        // CART-15-006	댓글 정보 조회
        , get_bbs_comment: 'mafiaa/cart/postcommentinfoinquiry/::1.1.0'
        // 회원가입
        , post_join: '9'
        // 회원가입 - 구글
        , post_join_with_google: '10'
        // MEM-05-001 로그인 소셜 인증 요청
        , post_login_social: 'mafiaa/mem/loginsocialcertfc/::1.1.0'
        // 소셜 로그인 인증
        , post_confirm_social: 'mafiaa/mem/gogleotpchgsocialcertfcconfirmation'
        // 로그아웃
        , post_logout: 'mafiaa/mem/memberlogout'
        // 회원가입 - 메일 확인
        , post_check_email: 'mafiaa/mem/loginsocialcertfc'
        // COM-02-002 회원 가입 카르텔 목록 조회
        , get_member_cartel_list: 'mafiaa/com/memberentrycartllist/::1.1.0'
        // MEM-11-001  공지글 알림 카르텔 목록 조회
        , post_cartel_notice_alram: 'mafiaa/mem/cartlnoticenotesettup/::1.1.0'
        // MEM-12-002	댓글 알림 카르텔 목록 조회
        , post_cartel_comment_alram: 'mafiaa/mem/commentnotesettup/::1.1.0'
        // 외부 NFT 카드 목록
        , get_nft_outside_list: '11'
        // CART-24-001	카르텔 설정 정보 조회
        , get_cartel_permission: 'mafiaa/cart/cartlsettupinfoinquiry/::1.1.0'
        // CART-25-001	카르텔 전체 회원 검색
        , get_cartel_member_search: 'mafiaa/cart/cartlallmembersearch/::1.1.0'
        // CART-25-002	카르텔 회원 등급정보 조회
        , get_cartel_member_grade: 'mafiaa/cart/cartlmembergradeinfoinquiry/::1.1.0'
        // CART-26-001 카르텔 알림 조회
        , get_cartel_alram: 'mafiaa/cart/cartlnoteinquiry/::1.1.0'
        // CART-17-001	등록용 게시판 목록 조회
        , get_board_list: 'mafiaa/cart/regboardinquiry/::1.1.0'
        // CART-17-002	게시물 정보 조회
        , get_cartel_bbs_info: 'mafiaa/cart/postinfoinquiry/::1.1.0'
        // CART-17-003	게시물 등록 요청
        , post_bbs: 'mafiaa/cart/postreg/::1.1.0'
        // CART-17-004	게시물  수정 요청
        , post_bbs_modify: 'mafiaa/cart/postchange/::1.1.0'
        // CART-17-005	게시물 삭제 요청
        , post_delete_bbs: 'mafiaa/cart/postdelete/::1.1.0'
        // CART-17-006	게시물 이동 요청
        , post_board_move: 'mafiaa/cart/postmoving/::1.1.0'
        // COM-05-006 앱버전정보조회
        , get_app_info: 'mafiaa/com/appversioninfoinquiry'
        // MEM-20-001	회웥 탈퇴
        , post_member_out: 'mafiaa/mem/memberwithddt'
        // MEM-22-002 핀 번호 분실 등록 구글 OTP 인증
        , post_otp_for_pin: 'mafiaa/mem/pinnumberlostreggogleotpcertfc'
        // MEM-23-001	회원 알림 목록 조회
        , get_notify_list: 'mafiaa/mem/membernotelistinquiry'
        // COM-02-007	회원 가입 카르텔 게시물 목록 조회 : 개발전
        , get_comm_cartel_board_list: 'mafiaa/com/memberentrycartlpostlistinquiry/::1.1.0'
        // COM-02-008	회원 최근 알림 조회
        , get_comm_notify: 'mafiaa/com/membernrtnoteinquiry'
        // COM-05-009 로그인 메인 팝업 정보 조회
        , get_system_notice: 'mafiaa/com/loginmainpopupinfoinquiry'
        // CART-09-006	카르텔 회원 등급 목록 조회 요청
        , get_cartel_member_grade_list: 'mafiaa/cart/cartlmembergradelistinquiry'
        // CART-09-007	카르텔 회원 등급 저장 요청
        , post_cartel_member_grade: 'mafiaa/cart/cartlmembergradesave'
        // CART-09-008	카르텔 회원 등급 삭제 요청
        , post_cartel_member_grade_delete: 'mafiaa/cart/cartlmembergradedelete'

    }
}