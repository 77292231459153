import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueDaumPostcode from "vue-daum-postcode"
import VueGoogleCharts from 'vue-google-charts'
import { language } from '@/resources/lang/kr/common'
import { sample } from '@/resources/sample/sample'
import GAuth from 'vue-google-oauth2'
import {terms} from "@/resources/agree/terms";
import {personal} from "@/resources/agree/personal";
import {Axios} from "@/resources/axios/axios";
import Loading from "@/view/Layout/Loading"
import Notify from "@/components/AlertMsg"
import { rules } from "@/resources/rules/rules"
import { storage } from "./resources/storage/storage"
import { request} from "@/resources/request/request"
import { date } from "@/resources/date/date"
import LongPress from 'vue-directive-long-press'
import { common } from '@/assets/js/common'
import VueQRCodeComponent from 'vue-qrcode-component'
import { encodeStorage} from "@/resources/storage/localStorage";
import VueAppleLogin from 'vue-apple-login';
import VueBarcode from 'vue-barcode';
import filter from '@/resources/filter/filter'
import { api_url } from '@/resources/axios/api_url.js'
import { codes } from "@/resources/config/codes";

let location = window.location.href
let domain = process.env.VUE_APP_DOMAIN
let domain_dev = process.env.VUE_APP_DOMAIN_DEV
let domain_m = process.env.VUE_APP_DOMAIN_M
let baseUrl = domain_dev
// 운영계 API 호출
if(location.indexOf(domain) > -1 || location.indexOf(domain_m) > -1) {
  baseUrl = domain
}

Vue.use(VueAppleLogin, {
  clientId: 'mafinft.coinvest.com',
  scope: 'email',
  redirectURI: baseUrl + '/auth/apple_return',
  state: 'state',
  usePopup: true,
});

Vue.config.productionTip = false
Vue.prototype.$language = language
Vue.prototype.$terms = terms
Vue.prototype.$personal = personal
Vue.prototype.$sample = sample
Vue.prototype.$Axios = Axios
Vue.prototype.$Loading = Loading
Vue.prototype.$Notify = Notify
Vue.prototype.$rules = rules
Vue.prototype.$storage = storage
Vue.prototype.$request = request
Vue.prototype.$date = date
Vue.prototype.$common = common
Vue.prototype.$encodeStorage = encodeStorage
Vue.prototype.$filter = filter
Vue.prototype.$api_url = api_url
Vue.prototype.$codes = codes

Vue.component('qr-code', VueQRCodeComponent)
Vue.component('barcode', VueBarcode)

let img_url = ''
if(process.env.VUE_APP_TYPE == 'sample'){
  img_url = '@/assets/image/'
}else{
  img_url = ''
}

Vue.prototype.$upload_img = img_url

Vue.use(VueDaumPostcode)
Vue.use(VueGoogleCharts)
Vue.directive('long-press', LongPress)

const gauthOption = {
  clientId: process.env.VUE_APP_CLIENTID,
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)

Vue.prototype.$bus = new Vue()



/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(fas)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
