<template>
	<div
		class="position-fixed-full radius-20 z-index-layer"
	>
		<div class="bg-layer"></div>
		<div
			class="position-relative viewer pa-20 flex-column justify-center full-width full-height"
		>
			<div>
				<slot name="body"></slot>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: 'PopupLayer'
}
</script>